import { useEffect, useState, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { chunk } from "lodash";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { linkIcon } from "../../../utils/iconUtils";
import { getBaseUrl } from "../../../config/environment";
import { useNavigate } from "react-router-dom";
import ImageSvgs from "../../ImageSvgs";
import { getHostUrl } from "../../../config/environment";
import { getData } from "../../../services/apiService";
import noConnections from "../../../assets/images/noConnections.svg";
import MyVerticallyCenteredModal from "../../CommonComponents/ReadMore";
import CarouselExpertComponent from "../../CommonComponents/CarouselExpertComponent";
import CarouselConnections from "../../CommonComponents/CarouselConnections";
import { ProcessDeleteModal } from "../../../utils/processUtil";
import ProcessSteps from "../ProcessSteps";
import Loader from "../../Loader/Loader";
import { RiFileDownloadLine } from "react-icons/ri";
import CustomBadge from "../../CustomBadge";
import "./ProcessUserPage.scss";
import "../../../assets/scss/common.scss";

const downloadIcon = (
  <span className="download-icon">
    <RiFileDownloadLine />
  </span>
);

export default function ProcessUserPage(props) {
  const [activeProcess, setActiveProcess] = useState();
  const [includedData, setIncludedData] = useState();
  const [group, setGroup] = useState();
  const [owner, setOwner] = useState();
  const [copyPageUrl, setCopyPageUrl] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const contentRef = useRef(null);
  const containerRef = useRef(null);
  const adminCheck = sessionStorage.getItem("adminCheck");
  const pageUrl = window.location.href;
  const [activeButton, setActiveButton] = useState("3");
  const [appData, setAppData] = useState([]);
  const [trainingData, setTrainingData] = useState([]);
  const [commData, setCommData] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [selectedProcessFilter, setSelectedProcessFilter] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const selectedProcessFilterStr = params.get("selectedProcessFilter");

    if (selectedProcessFilterStr) {
      try {
        const decodedFilterStr = decodeURIComponent(selectedProcessFilterStr);
        const parsedFilter = JSON.parse(decodedFilterStr);
        setSelectedProcessFilter(parsedFilter);
      } catch (error) {
        console.error("Error parsing selectedProcessFilter:", error);
        setSelectedProcessFilter(null);
      }
    }
  }, [location.search]);

  const orgQuery = selectedProcessFilter?.orgQuery || [];
  const groupQuery = selectedProcessFilter?.groupQuery || [];

  const addQueryParam = (key, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.pushState({}, "", url.toString());
  };

  useEffect(() => {
    (async () => {
      const url = new URL(window.location.href);
      let id = url.searchParams.get("process_id");
      setProcess(id);
    })();

    // eslint-disable-next-line
  }, []);

  const checkOverflow = useCallback(() => {
    if (containerRef.current && contentRef.current) {
      const container = containerRef.current;
      const content = contentRef.current;
      setIsOverflowing(content.scrollHeight > container.clientHeight);
    }
  }, []);

  useEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [activeProcess, checkOverflow]);

  const setProcess = async (data) => {
    const param = `${getHostUrl()}process/${data.toString()}?include=owners,trainings,communities,apps,child_processes,parent_processes,orgs&use_pagination=false`;
    const res = await getData(param);
    let processVal = res.data.data;

    setActiveProcess(processVal);
    setIncludedData(res?.data?.included);
    addQueryParam("process_id", processVal && processVal.id);

    if (processVal?.attributes.desc?.length > 300) {
      setIsOverflowing(true);
    }

    const group =
      processVal.attributes["process-group"] !== null
        ? processVal.attributes["process-group"].data?.attributes
        : null;
    setGroup(group);

    let ownerData =
      processVal?.relationships?.owners?.data?.length > 0
        ? processVal?.relationships?.owners?.data?.map((item) => {
            return res?.data?.included?.filter(
              // eslint-disable-next-line
              (owners) =>
                owners.type === "owners" &&
                owners?.id?.toString() === item?.id?.toString()
            )[0]?.attributes?.employee?.data;
          })
        : [];

    let tempOwnerData = chunk(
      ownerData
        .sort((a, b) =>
          a.attributes["preferred-name"].localeCompare(
            b.attributes["preferred-name"]
          )
        )
        .sort(
          (a, b) =>
            Number(
              res?.data?.included?.filter(
                // eslint-disable-next-line
                (owners) =>
                  owners.type === "owners" &&
                  owners?.attributes["employee-id"].toString() ===
                    b.id.toString()
              )[0].attributes["is-owners"]
            ) -
            Number(
              res?.data?.included?.filter(
                // eslint-disable-next-line
                (owners) =>
                  owners.type === "owners" &&
                  owners.attributes["employee-id"].toString() ===
                    a.id.toString()
              )[0].attributes["is-owners"]
            )
        ),
      4
    );

    processVal.relationships.apps.data.map((elem) => {
      const temp = res.data.included?.filter(
        (app) =>
          app.type === "application" && app.id.toString() === elem.id.toString()
      )[0].attributes["apps-process"].data;
      setAppData((_) => [..._, temp]);
      return null;
    });

    processVal?.relationships.communities.data.map((item) => {
      let community = res?.data?.included?.filter(
        (com) =>
          com.type === "community" && com.id.toString() === item.id.toString()
      )[0].attributes["process-communities"]?.data;
      setCommData((_) => [..._, community]);
      return null;
    });

    processVal?.relationships.trainings.data.map((item) => {
      let trainings = res?.data?.included?.filter(
        (value) =>
          value.type === "trainings" &&
          value.id.toString() === item.id.toString()
      )[0].attributes["training-master"]?.data;
      setTrainingData((_) => [..._, trainings]);
      return null;
    });

    processVal?.relationships.orgs.data.map((item) => {
      let orgs = res?.data?.included?.filter(
        (org) => org.type === "orgs" && org.id.toString() === item.id.toString()
      )[0].attributes["org-master"]?.data;
      setOrgData((_) => [..._, orgs]);
      return null;
    });
    setOwner(tempOwnerData);
  };

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  const getFilteredData = () => {
    switch (activeButton) {
      case "1":
        return commData;
      case "2":
        return trainingData;
      case "3":
        return appData;
      case "4":
        return orgData;
      default:
        return [];
    }
  };

  return (
    <>
      <div
        onClick={() => {
          props?.hide();
        }}
      >
        <Link
          className="process-btn"
          to={`/landscape/process?process_id=0&view=${
            props.view
          }&selectedProcessFilter=${encodeURIComponent(
            JSON.stringify({
              orgQuery,
              groupQuery,
            })
          )}`}
          state={{ prevPath: window.location.href }}
        >
          <ImageSvgs name="goBack" />
          <span className="fw-bold pt-2"> Back </span>
        </Link>
      </div>

      {activeProcess ? (
        <div className="rightSide d-flex flex-column w-100 ">
          <div className="titDiv p-3">
            <div className="innerDiv border-bottom pb-2">
              <div className="details d-flex flex-column ">
                <div className="d-flex flex-column">
                  <div className="d-flex flex-row pb-2 justify-content-between">
                    <span className="mt-2">
                      <span className="process-title">
                        <strong>{activeProcess?.attributes?.title}</strong>
                      </span>

                      <span className="px-1">
                        <button
                          className=" bg-transparent border-0"
                          data-toggle="tooltip"
                          data-placement="top"
                          title={!copyPageUrl ? "Copy Page Url" : "Copied"}
                          disabled={copyPageUrl}
                          onClick={() => {
                            navigator.clipboard.writeText(pageUrl);
                            setCopyPageUrl(true);
                            setTimeout(() => {
                              setCopyPageUrl(false);
                            }, 1000);
                          }}
                        >
                          {copyPageUrl === false ? (
                            <>
                              <ImageSvgs name="expertCopy" />
                            </>
                          ) : (
                            <>
                              <ImageSvgs name="tickMark" />
                            </>
                          )}
                        </button>
                      </span>
                    </span>

                    {adminCheck && adminCheck === "true" ? (
                      <span className="d-flex">
                        <Button
                          disabled={props?.allLoading ? true : false}
                          onClick={() => {
                            sessionStorage.setItem("processFlag", "true");
                            sessionStorage.setItem(
                              "processEditData",
                              JSON.stringify(activeProcess && activeProcess)
                            );
                            sessionStorage.setItem(
                              "processIncluded",
                              JSON.stringify(includedData && includedData)
                            );
                            sessionStorage.setItem(
                              "updateId",
                              activeProcess.id
                            );
                            navigate("/manage_process?page=editProcess", {
                              replace: true,
                            });
                          }}
                          className="app-tertiary-btn"
                        >
                          Edit Process
                        </Button>

                        <span className="fw-bold mt-2 value-seperator">|</span>
                        <Button
                          className="app-tertiary-btn"
                          onClick={() => {
                            setShowModal(true);
                          }}
                        >
                          Delete Process
                        </Button>
                      </span>
                    ) : null}
                  </div>

                  <div className="pb-2 d-flex flex-row">
                    <div className="d-flex flex-row">
                      <div className="min-width ">
                        <strong>Description</strong>
                      </div>
                      <span>
                        <strong>:</strong>
                      </span>
                    </div>

                    <div className="px-1">
                      <div ref={containerRef} className="desc-overflow">
                        <span
                          className={`mx-0 d-flex flex-column ${
                            isOverflowing ? "limited-space" : "not-read-more"
                          }`}
                          ref={contentRef}
                        >
                          <div className="pro-desc">
                            {activeProcess?.attributes?.desc?.replace(
                              /\\n/g,
                              "\n"
                            )}
                          </div>
                        </span>
                      </div>
                      {isOverflowing && (
                        <p
                          className="mx-0 mb-0 read-more-btn"
                          onClick={() => setShowInfoModal(true)}
                        >
                          Read more
                        </p>
                      )}
                    </div>
                  </div>

                  <div className=" d-flex flex-row pb-2">
                    <span className="min-width">
                      <strong>Periodicity</strong>
                    </span>
                    <span>
                      <strong>:</strong>
                    </span>
                    <span className="px-1">
                      <span>
                        {activeProcess?.attributes?.["periodicity-period"] === 1
                          ? "Monthly Based Process"
                          : activeProcess?.attributes?.[
                              "periodicity-period"
                            ] === 2
                          ? "Quartely Based Process"
                          : activeProcess?.attributes?.[
                              "periodicity-period"
                            ] === 3
                          ? "Annually Based Process"
                          : "N/A"}
                      </span>
                    </span>
                  </div>

                  <div className=" d-flex flex-row pb-2">
                    <span className="min-width">
                      <strong>Start Date & Time</strong>
                    </span>
                    <span>
                      <strong>:</strong>
                    </span>
                    {activeProcess?.attributes?.["process-day"] ||
                    activeProcess?.attributes?.["process-time"] ? (
                      <div
                        className="d-flex flex-row 
                    "
                      >
                        <span className="px-1 ">
                          {" "}
                          {activeProcess?.attributes?.["process-day"]}
                        </span>
                        <span>
                          {activeProcess?.attributes?.["process-day"] &&
                          activeProcess?.attributes?.["process-time"]
                            ? ","
                            : " "}
                        </span>{" "}
                        <span className="px-2">
                          {activeProcess?.attributes?.["process-time"]}
                        </span>
                      </div>
                    ) : (
                      <span className="px-1 text-muted"> N/A</span>
                    )}
                  </div>

                  <div className=" d-flex flex-row pb-2">
                    <span className="min-width">
                      <strong>End Date & Time</strong>
                    </span>
                    <span>
                      <strong>:</strong>
                    </span>
                    {activeProcess?.attributes?.["end-day"] ||
                    activeProcess?.attributes?.["end-time"] ? (
                      <div
                        className="d-flex flex-row 
                    "
                      >
                        <span className="px-1 ">
                          {" "}
                          {activeProcess?.attributes?.["end-day"]}
                        </span>
                        <span>
                          {activeProcess?.attributes?.["end-day"] &&
                          activeProcess?.attributes?.["end-time"]
                            ? ","
                            : " "}
                        </span>{" "}
                        <span className="px-2">
                          {activeProcess?.attributes?.["end-time"]}
                        </span>
                      </div>
                    ) : (
                      <span className="px-1 text-muted"> N/A</span>
                    )}
                  </div>
                  <div className="d-flex flex-row pb-2">
                    <span className="min-width">
                      <strong>Receives from</strong>
                    </span>
                    <span>
                      <strong>:</strong>
                    </span>

                    {activeProcess?.attributes?.["received-from"] &&
                    activeProcess?.attributes?.["received-from"]?.data.length >
                      0 ? (
                      <div className="d-flex flex-column ms-1 pro-connections">
                        {chunk(
                          activeProcess?.attributes?.[
                            "received-from"
                          ]?.data.sort((a, b) =>
                            a.attributes["title"].localeCompare(
                              b.attributes["title"]
                            )
                          ),
                          3
                        ).map((record, i) => {
                          return (
                            <div className="d-flex mb-2" key={i}>
                              {record.map((elem, index) => {
                                return (
                                  <div
                                    className="d-flex me-2 grid-links"
                                    key={index}
                                  >
                                    <a
                                      href={`/landscape/process?process_id=${elem.id}&view=${props.view}`}
                                      className="trainings-link fw-normal my-auto ms-1"
                                    >
                                      {elem.attributes["title"]}
                                    </a>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <span className="px-1 pb-2"> N/A</span>
                    )}
                  </div>
                  <div className="d-flex flex-row pb-2">
                    <span className="min-width">
                      <strong>Delivers to</strong>
                    </span>
                    <span>
                      <strong>:</strong>
                    </span>

                    {activeProcess?.attributes?.["delivered-to"] &&
                    activeProcess?.attributes?.["delivered-to"]?.data.length >
                      0 ? (
                      <div className="d-flex flex-column ms-1 pro-connections">
                        {chunk(
                          activeProcess?.attributes?.[
                            "delivered-to"
                          ]?.data.sort((a, b) =>
                            a.attributes["title"].localeCompare(
                              b.attributes["title"]
                            )
                          ),
                          3
                        ).map((record, i) => {
                          return (
                            <div className="d-flex mb-2" key={i}>
                              {record.map((elem, index) => {
                                return (
                                  <div
                                    className="d-flex me-2 grid-links"
                                    key={index}
                                  >
                                    <a
                                      href={`/landscape/process?process_id=${elem.id}&view=${props.view}`}
                                      className="trainings-link fw-normal my-auto ms-1"
                                    >
                                      {elem.attributes["title"]}
                                    </a>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <span className="px-1 pb-2"> N/A</span>
                    )}
                  </div>

                  <div className="d-flex flex-row pb-2">
                    <span className="min-width">
                      <strong>Group</strong>
                    </span>
                    <span>
                      <strong>:</strong>
                    </span>
                    <div className="d-flex flex-row w-100 ms-1">
                      {group ? (
                        <CustomBadge
                          title={group.title}
                          color={group["node-color"]}
                          textColor={group["font-color"]}
                        />
                      ) : (
                        <span className="ms-1">N/A</span>
                      )}{" "}
                    </div>
                  </div>

                  <div className="d-flex flex-row pb-2">
                    <span className="min-width">
                      <strong>Process References</strong>
                    </span>
                    <span>
                      <strong>:</strong>
                    </span>

                    {activeProcess?.attributes?.["reference-urls"] &&
                    activeProcess?.attributes?.["reference-urls"]?.data.length >
                      0 ? (
                      <div className="d-flex flex-column ms-1 pro-connections">
                        {chunk(
                          activeProcess?.attributes?.[
                            "reference-urls"
                          ]?.data.sort((a, b) => a.name.localeCompare(b.name)),
                          3
                        ).map((record, i) => {
                          return (
                            <div className="d-flex mb-2" key={i}>
                              {record.map((elem, index) => {
                                return (
                                  <div
                                    className="d-flex me-2 grid-links"
                                    key={index}
                                  >
                                    <span className="link-icon">
                                      {linkIcon}
                                    </span>
                                    <a
                                      href={elem.link}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="trainings-link fw-normal my-auto ms-1"
                                    >
                                      {elem.name}
                                    </a>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <span className="px-1 pb-2"> N/A</span>
                    )}
                  </div>
                  <div className="d-flex flex-row pb-2">
                    <span className="min-width">
                      <strong>Process Files</strong>
                    </span>
                    <span>
                      <strong>:</strong>
                    </span>
                    {activeProcess?.attributes?.["template-urls"] &&
                    activeProcess?.attributes?.["template-urls"]?.data.length >
                      0 ? (
                      <div className="d-flex flex-row ms-2 w-100">
                        {activeProcess?.attributes?.["template-urls"]?.data
                          .sort((a, b) =>
                            a.file_name.localeCompare(b.file_name)
                          )
                          .map((elem, index) => {
                            return (
                              <div
                                className="d-flex me-2 grid-links"
                                key={index}
                              >
                                <span>{downloadIcon}</span>
                                <Button
                                  title="Downlaod File"
                                  className="download-file fw-normal p-0 ps-1"
                                  onClick={async () => {
                                    const url = `${getBaseUrl()}${
                                      elem?.file_path
                                    }`;

                                    await axios({
                                      url,
                                      method: "GET",
                                      responseType: "blob",
                                    })
                                      .then((response) => {
                                        const href = window.URL.createObjectURL(
                                          response.data
                                        );

                                        const anchorElement =
                                          document.createElement("a");
                                        anchorElement.href = href;
                                        anchorElement.download =
                                          elem?.file_name;

                                        document.body.appendChild(
                                          anchorElement
                                        );
                                        anchorElement.click();

                                        document.body.removeChild(
                                          anchorElement
                                        );
                                        window.URL.revokeObjectURL(href);
                                      })
                                      .catch((error) => {
                                        return error;
                                      });
                                  }}
                                >
                                  {elem?.file_name}
                                </Button>
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      <span className="px-1"> N/A</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="d-flex  flex-column experts-comp border-bottom pb-3 p-3">
              <h6 className="fw-bold me-3 my-auto">
                Owners & Deputies of {activeProcess?.attributes?.title} are :{" "}
              </h6>
              <div className="experts-section mt-3">
                <div className="d-flex flex-row justify-content-between">
                  {owner?.length > 0 ? (
                    <CarouselExpertComponent
                      data={owner}
                      includedData={includedData && includedData}
                    />
                  ) : null}
                </div>
              </div>
            </div>

            <div className="section">
              <div>
                <div className="d-flex flex-column mt-3">
                  <h6>
                    <b>{activeProcess?.attributes.title} is connected to : </b>
                  </h6>

                  <div>
                    <div className="button-group-container">
                      <ButtonGroup className="my-2 w-100">
                        <Button
                          className={
                            activeButton === "3"
                              ? "form-select-btn form-select-activeBtn w-25"
                              : "form-select-btn w-25"
                          }
                          onClick={() => handleButtonClick("3")}
                        >
                          Applications
                        </Button>
                        <Button
                          className={
                            activeButton === "1"
                              ? "form-select-btn form-select-activeBtn w-25"
                              : "form-select-btn w-25"
                          }
                          onClick={() => handleButtonClick("1")}
                        >
                          Communities
                        </Button>

                        <Button
                          className={
                            activeButton === "4"
                              ? "form-select-btn form-select-activeBtn w-25"
                              : "form-select-btn w-25"
                          }
                          onClick={() => handleButtonClick("4")}
                        >
                          Organizations
                        </Button>

                        <Button
                          className={
                            activeButton === "2"
                              ? "form-select-btn form-select-activeBtn w-25"
                              : "form-select-btn w-25"
                          }
                          onClick={() => handleButtonClick("2")}
                        >
                          Trainings
                        </Button>
                      </ButtonGroup>
                    </div>

                    <div className="banner-carousel">
                      <div className="connections-change-section mt-2">
                        {getFilteredData().length === 0 ? (
                          <div className="d-flex justify-content-center no-data-container">
                            <div className="d-flex flex-column mt-2">
                              <img
                                className="mx-auto no-data"
                                src={noConnections}
                                alt="No Data"
                              />
                              <p className="mb-0 fw-bold">
                                No connections found!
                              </p>
                            </div>
                          </div>
                        ) : (
                          <CarouselConnections
                            data={chunk(getFilteredData(), 5)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <h6 className="pt-2">
                  {activeProcess.attributes["process-config"]?.nodes?.length >
                  0 ? (
                    <b>Process Steps :</b>
                  ) : (
                    <span className="d-flex">
                      <b className="me-2">Process Steps :</b>
                      <b className="text-muted">N/A</b>
                    </span>
                  )}
                </h6>
                {activeProcess.attributes["process-config"]?.nodes?.length >
                0 ? (
                  <>
                    <div className="d-flex flex-column mt-2">
                      <ProcessSteps processConfig={activeProcess} />
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className=" d-flex justify-content-center custom-loader">
          <Loader />
        </div>
      )}

      {showModal === true ? (
        <ProcessDeleteModal
          deleteProcessData={activeProcess && activeProcess}
          show={showModal}
          onHide={() => setShowModal(false)}
          hideProcess={props?.hide}
          deleted={props?.deleted}
          setIsDeleted={props?.setIsDeleted}
          setDeleteFlag={props.setDeleteFlag}
        />
      ) : null}

      {showInfoModal ? (
        <MyVerticallyCenteredModal
          show={showInfoModal}
          onHide={() => setShowInfoModal(false)}
          data={activeProcess && activeProcess}
        />
      ) : null}
    </>
  );
}
