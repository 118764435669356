import { React, useState, useEffect } from "react";
import { Dropdown, Button } from "react-bootstrap";
import ImageSvgs from "../../ImageSvgs";
import { MultipleSelectDropdown } from "../MultipleSelect";
import { Typeahead } from "react-bootstrap-typeahead";
import { useQueryParams } from "./queryParams";
import { useLocation } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "./GenericFilter.scss";

export default function GenericFilter({
  show,
  onToggle,
  onSelect,
  filterOptions,
  onApply,
  onCancel,
  title,
}) {
  const { filters, setFilters } = useQueryParams(filterOptions);
  const [tempFilters, setTempFilters] = useState(filters);
  const location = useLocation();

  const decodeFilterOptions = (searchParams) => {
    const decodedParams = {};
    for (const [key, value] of searchParams.entries()) {
      try {
        decodedParams[key] = JSON.parse(decodeURIComponent(value));
      } catch (error) {
        decodedParams[key] = decodeURIComponent(value);
      }
    }

    return decodedParams;
  };

  useEffect(() => {
    const searchFilterValues = new URLSearchParams(location.search);
    const decodedFilterValues = decodeFilterOptions(searchFilterValues);
    setTempFilters(decodedFilterValues);
    setFilters(decodedFilterValues);
    // eslint-disable-next-line
  }, [location.search, show]);

  const handleFilterChange = (queryKey, selectedValues) => {
    setTempFilters((prevFilters) => ({
      ...prevFilters,
      [queryKey]: selectedValues,
    }));
  };

  const handleApply = () => {
    setFilters(tempFilters);
    onApply(tempFilters);
  };

  const handleCancel = () => {
    setTempFilters(filters);
    onCancel();
  };

  const handleClearAll = () => {
    setTempFilters(
      filterOptions.reduce((acc, dropdown) => {
        acc[dropdown.queryKey] = [];
        return acc;
      }, {})
    );
  };

  const renderInputField = (dropdown) => {
    switch (dropdown.inputType) {
      case "multi-select":
        return (
          <div>
            <div className="pb-2">{dropdown.label}</div>
            <div className="app-primary-dropdown">
              <MultipleSelectDropdown
                color="#8A00E5"
                className="dropdown-select mb-2"
                multi={dropdown.multi}
                disabled={dropdown.disabled ? dropdown.disabled : false}
                placeholder={dropdown.placeholder}
                values={tempFilters[dropdown.queryKey] || dropdown.selected}
                onChange={(values) => {
                  handleFilterChange(dropdown.queryKey, values);
                  dropdown.onChange(values);
                }}
                options={dropdown.options?.map((value, index) => ({
                  type: value.type,
                  name: value.name,
                  id: value.id,
                }))}
              />
            </div>
          </div>
        );

      case "typeahead":
        return (
          <div>
            <div className="pb-2">{dropdown.label}</div>
            <div className="app-primary-dropdown ">
              <Typeahead
                labelKey="name"
                filterBy={["name"]}
                id="typeAhead"
                searchable
                emptyLabel={dropdown.emptyLabel}
                multi={dropdown.multi}
                highlightOnlyResult={true}
                clearButton={true}
                placeholder={dropdown.placeholder}
                options={dropdown.options}
                selected={tempFilters[dropdown.queryKey] || dropdown.selected}
                onChange={(values) =>
                  handleFilterChange(dropdown.queryKey, values)
                }
                onInputChange={dropdown.onInputChange}
              />
            </div>
          </div>
        );
      case "text":
        return (
          <div className="app-primary-input-field custom-search ">
            <div className="pb-2">{dropdown.label}</div>
            <input
              type="search"
              value={tempFilters[dropdown.queryKey] || dropdown.value || ""}
              onChange={(e) =>
                handleFilterChange(dropdown.queryKey, e.target.value)
              }
              placeholder={dropdown.placeholder}
            />
          </div>
        );
      case "employee-search":
        return (
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between pb-2">
              <div>{dropdown.label}</div>
              <Button
                onClick={dropdown.onButtonClick}
                className="app-icon-primary-btn mb-1"
                // style={{ width: "9%", justifyContent: "flex-end" }}
              >
                +
              </Button>
            </div>
            <div className="app-primary-input-field">
              <input
                type="input"
                disabled={true}
                value={tempFilters[dropdown.queryKey] || dropdown.value || ""}
                onChange={(e) =>
                  handleFilterChange(dropdown.queryKey, e.target.value)
                }
                placeholder={dropdown.placeholder}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Dropdown
        show={show}
        onToggle={onToggle}
        onSelect={onSelect}
        className="custom-dropdown-main"
      >
        <Dropdown.Toggle onClick={onSelect}>
          <ImageSvgs name="filterLogo" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="w-100 custom-dropdown">
          <div className="dropdown-title">{title}</div>
          {filterOptions?.map((input, index) => (
            <div className="pt-4 " key={index}>
              {renderInputField(input)}
            </div>
          ))}

          <div className="d-flex justify-content-end font-weight-bold pt-4">
            <Button
              className="app-secondary-btn ms-3 my-auto"
              onClick={handleClearAll}
            >
              Clear All
            </Button>
            <Button
              className="app-secondary-btn ms-3 my-auto"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button className="app-primary-btn ms-3" onClick={handleApply}>
              Apply
            </Button>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
