import { React, useState, useRef, useCallback, useEffect } from "react";
import ImageSvgs from "../ImageSvgs";
import { Link } from "react-router-dom";
import { getBaseUrl } from "../../config/environment";
import { Button, ButtonGroup } from "react-bootstrap";
import { Stack, Rating } from "@mui/material";
import CarouselExpertComponent from "../CommonComponents/CarouselExpertComponent";
import CarouselConnections from "../CommonComponents/CarouselConnections";
import MyVerticallyCenteredModal from "../CommonComponents/ReadMore";
import noConnections from "../../assets/images/noConnections.svg";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import chunk from "lodash/chunk";
import { getHostUrl } from "../../config/environment";
import { getData } from "../../services/apiService";
import Loader from "../Loader/Loader";
import LearningDeleteModal from "./LearningDeletModal/LearningDeleteModal";
import { deleteData } from "../../services/apiService";
import "./LearningDetailPage.scss";

export default function LearningDetailPage(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const contentRef = useRef(null);
  const containerRef = useRef(null);
  const adminCheck = sessionStorage.getItem("adminCheck");
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [courseId, setCourseId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [courseDetails, setCourseDetails] = useState([]);
  const [activeBtn, setActiveBtn] = useState("apps");
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const viewId = urlParams.get("viewId");

    if (viewId) {
      setCourseId(viewId);
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    if (courseId) {
      getCourseDetails(courseId);
    }
  }, [courseId]);

  useEffect(() => {
    getFilteredData();
    // eslint-disable-next-line
  }, [activeBtn]);

  const getCourseDetails = async (id) => {
    setLoading(true);
    const param = `${getHostUrl()}courses/${id?.toString()}`;
    const res = await getData(param);
    if (res.status === 200) {
      setCourseDetails(res.data.data);

      setLoading(false);
    }
  };

  const checkOverflow = useCallback(() => {
    if (containerRef.current && contentRef.current) {
      const content = contentRef.current;
      const clientH = 56;
      setIsOverflowing(content.scrollHeight > clientH);
    }
  }, []);

  useEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
    // eslint-disable-next-line
  }, [courseDetails.attributes?.["courses-description"], checkOverflow]);

  const readMoreData = {
    type: "learning",
    attributes: {
      courseTitle: courseDetails?.attributes?.["courses-title"],
      courseDesc: courseDetails?.attributes?.["courses-description"]?.replace(
        /\\n/g,
        "\n"
      ),
    },
  };

  const handleConnections = (activeTab) => {
    setActiveBtn(activeTab);
  };

  useEffect(() => {
    getFilteredData();
    // eslint-disable-next-line
  }, [activeBtn]);

  const getFilteredData = () => {
    switch (activeBtn) {
      case "apps":
        return courseDetails?.attributes?.["generic-apps"]?.data;
      case "tools":
        return courseDetails?.attributes?.["generic-tools-master"]?.data;
      case "proCons":
        return courseDetails?.attributes?.["generic-process"]?.data;
      default:
        return [];
    }
  };

  const redirectCourse = (link) => {
    window.open(link, "_blank");
  };

  const refinedOwnerData = courseDetails?.attributes?.["owners"]?.data?.map(
    (owner) => ({
      id: owner.attributes.employee.data.id,
      type: "employee",
      attributes: {
        "email-id": owner.attributes.employee.data.attributes["email-id"],
        "employee-org-master":
          owner.attributes.employee.data.attributes["employee-org-master"],
        "f-name": owner.attributes.employee.data.attributes["f-name"],
        gid: owner.attributes.employee.data.attributes["gid"],
        "job-title": owner.attributes.employee.data.attributes["job-title"],
        "l-name": owner.attributes.employee.data.attributes["l-name"],
        "org-id": owner.attributes.employee.data.attributes["org-id"],
        "preferred-name":
          owner.attributes.employee.data.attributes["preferred-name"],
        "profile-picture":
          owner.attributes.employee.data.attributes["profile-picture"],
      },
    })
  );

  const handleEditLearning = (id) => {
    navigate(`/manage_learnings/edit_learning/${id}`);
  };

  const handleCourseDelete = () => {
    setShowDeleteModal(true);
  };

  const deleteCourse = async () => {
    props.setUpdateData(true);
    const headers = {
      Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
    };
    const res = await deleteData(`${getHostUrl()}courses/${courseId}`, headers);
    if (res.status === 204) {
      props.setUpdateData(false);
      setShowDeleteModal(false);
      props.showCardDetails(false);
      navigate(`/landscape/finergy/learning/${props.parentId}`);
    }
  };

  const queryParams = location.search;
  const currentPath = location.pathname;
  const searchParams = new URLSearchParams(queryParams);
  searchParams.delete("viewId");
  const cleanedQueryString = searchParams.toString();
  let newPath = currentPath.split("?")[0];
  const newSearch = cleanedQueryString ? `?${cleanedQueryString}` : "";

  return (
    <div className="main-learning-details-page">
      {loading === false ? (
        <>
          <div
            onClick={() => {
              props?.onHide();
            }}
            className="pb-2"
          >
            <Link
              className="back-btn"
              //   state={{ prevPath: window.location.href }}
              to={{
                pathname: newPath,
                search: newSearch,
              }}
            >
              <ImageSvgs name="goBack" />
              <span className="fw-bold"> Back </span>
            </Link>
          </div>

          <div className="d-flex flex-row pb-2 border-bottom">
            <div>
              <img
                className="course-img"
                src={
                  courseDetails?.attributes?.["course-image"] != null
                    ? `${getBaseUrl()}${
                        courseDetails?.attributes?.["course-image"]
                      }`
                    : `${getBaseUrl()}/images/default-images/default-news.jpg`
                }
                alt="course"
              />
            </div>
            <div className="d-flex flex-column w-100">
              <div className="d-flex flex-row justify-content-between">
                <div className="d-flex">
                  <div id="Course Title" className="course-title">
                    <strong>
                      {courseDetails?.attributes?.["courses-title"]}{" "}
                    </strong>
                  </div>
                  <div>
                    <Link
                      href={courseDetails?.attributes?.["courses-redirect-url"]}
                      target="_blank"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          courseDetails?.attributes?.["courses-redirect-url"],
                          "_blank"
                        );
                      }}
                      className="pe-2"
                    ></Link>
                  </div>
                </div>

                <div className="d-flex flex-row">
                  <span className="d-flex ">
                    <Button
                      className="app-tertiary-btn"
                      onClick={() =>
                        redirectCourse(
                          courseDetails?.attributes?.["courses-redirect-url"]
                        )
                      }
                    >
                      {" "}
                      Go to Learning
                    </Button>
                  </span>
                  {adminCheck === "true" && (
                    <>
                      <span className="fw-bold mt-2 value-seperator"> |</span>
                      <Button
                        className="app-tertiary-btn"
                        onClick={() => handleEditLearning(courseId)}
                      >
                        {" "}
                        Edit Learning
                      </Button>
                      <>
                        {courseDetails?.attributes?.["created-by"] ===
                        "system" ? null : (
                          <>
                            <span className="fw-bold mt-2 value-seperator">
                              {" "}
                              |
                            </span>
                            <Button
                              className="app-tertiary-btn"
                              onClick={() => handleCourseDelete()}
                            >
                              {" "}
                              Delete Learning{" "}
                            </Button>
                          </>
                        )}
                      </>
                    </>
                  )}
                </div>
              </div>
              <>
                <div className="px-1">
                  <div className="desc-overflow" ref={containerRef}>
                    <span
                      className={`mx-0 d-flex flex-column ${
                        isOverflowing ? "limited-space" : "not-read-more"
                      }`}
                      ref={contentRef}
                    >
                      <div className="pro-desc">
                        {courseDetails?.attributes?.[
                          "courses-description"
                        ]?.replace(/\\n/g, "\n")}
                      </div>
                    </span>
                  </div>
                  {isOverflowing && (
                    <p
                      className="mx-0 mb-0 read-more-btn"
                      onClick={() => setShowInfoModal(true)}
                    >
                      Read more
                    </p>
                  )}
                </div>
              </>
            </div>
          </div>

          <div
            className={`owners pb-2 border-bottom ${
              courseDetails?.attributes?.["owners"]?.data?.length > 0
                ? ""
                : "d-flex"
            }`}
          >
            <h6 className="fw-bold me-3 my-auto pt-3">
              Owners of {courseDetails?.attributes?.["courses-title"]} :{" "}
            </h6>
            <div className="experts-section mt-3">
              {courseDetails?.attributes?.["owners"]?.data?.length > 0 ? (
                <div className="d-flex flex-row justify-content-between">
                  <CarouselExpertComponent data={[refinedOwnerData]} />
                </div>
              ) : (
                "N/A"
              )}
            </div>
          </div>

          <div className="course-connections border-bottom  d-flex flex-column pt-2 pb-2">
            <div className="d-flex flex-row pb-2">
              {" "}
              <span className="min-width">
                <strong>Ratings</strong>
              </span>
              <span>
                <strong>:</strong>
              </span>
              <div className="px-2 d-flex flex-row align-items-center">
                <Stack spacing={1}>
                  <Rating
                    name="simple-controlled"
                    defaultValue={
                      courseDetails?.attributes?.["courses-average-rating"]
                    }
                    size="small"
                    readOnly
                  />
                </Stack>

                <div className="px-1 fw-bold">
                  {courseDetails?.attributes?.["courses-average-rating"] ===
                  null
                    ? "0.0"
                    : courseDetails?.attributes?.[
                        "courses-average-rating"
                      ]?.toFixed(1)}{" "}
                  / 5.0
                </div>
              </div>
            </div>
            <div className="d-flex flex-row pb-2">
              <span className="min-width">
                <strong>Sources</strong>
              </span>
              <span>
                <strong>:</strong>
              </span>
              <div className="d-flex flex-column px-2 ">
                {courseDetails?.attributes?.["created-by"] === "system" ? (
                  <div className="source"> SELP </div>
                ) : (
                  <div className="source"> SEFC </div>
                )}
              </div>
            </div>

            <div className="d-flex flex-row pb-2">
              {" "}
              <span className="min-width">
                <strong>Languages</strong>
              </span>
              <span>
                <strong>:</strong>
              </span>
              {courseDetails?.attributes?.["lang"] &&
              courseDetails?.attributes?.["lang"]?.data?.length > 0 ? (
                <div className="d-flex flex-column grid-connections px-2">
                  {chunk(
                    courseDetails?.attributes?.["lang"]?.data?.sort((a, b) =>
                      a?.attrributes?.["lang-name"].localeCompare(
                        b?.attributes?.["lang-name"]
                      )
                    ),
                    3
                  ).map((record, i) => {
                    return (
                      <div className="d-flex mb-2 align-content" key={i}>
                        {record.map((elem, index) => {
                          return (
                            <div className="d-flex grid-links " key={index}>
                              {elem.attributes["lang-name"]}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <span className="px-1 pb-2"> N/A</span>
              )}
            </div>

            <div className="d-flex flex-row pb-2">
              <span className="min-width">
                <strong>Delivery</strong>
              </span>
              <span>
                <strong>:</strong>
              </span>
              {courseDetails?.attributes?.["class-delivery-name-data"] &&
              courseDetails?.attributes?.["class-delivery-name-data"]?.data
                ?.length > 0 ? (
                <div className="d-flex flex-column grid-connections px-2">
                  {chunk(
                    courseDetails?.attributes?.[
                      "class-delivery-name-data"
                    ]?.data?.sort((a, b) =>
                      a?.attrributes?.["delivery-name"].localeCompare(
                        b?.attributes?.["delivery-name"]
                      )
                    ),
                    3
                  ).map((record, i) => {
                    return (
                      <div className="d-flex mb-2 align-content" key={i}>
                        {record.map((elem, index) => {
                          return (
                            <div className="d-flex grid-links" key={index}>
                              {elem?.attributes?.["delivery-name"]}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <span className="px-1 pb-2"> N/A</span>
              )}
            </div>

            <div className="d-flex flex-row pb-2">
              {" "}
              <span className="min-width">
                <strong>Finance Fields</strong>
              </span>
              <span>
                <strong>:</strong>
              </span>
              {/* {courseDetails?.attributes?.["class-delivery-name-data"] &&
              courseDetails?.attributes?.["class-delivery-name-data"]?.data
                ?.length > 0 ? (
                <div className="d-flex flex-column grid-connections px-2">
                  {chunk(
                    courseDetails?.attributes?.[
                      "class-delivery-name-data"
                    ]?.data?.sort((a, b) =>
                      a["FieldTitle"].localeCompare(b["FieldTitle"])
                    ),
                    3
                  ).map((record, i) => {
                    return (
                      <div
                        className="d-flex mb-2 section-content-align"
                        key={i}
                      >
                        {record.map((elem, index) => {
                          return (
                            <div className="d-flex grid-links" key={index}>
                              <a
                                href={`/landscape/finergy/learning`}
                                className="trainings-link fw-normal my-auto ms-1 pb-0"
                              >
                                {elem.FieldTitle}
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <span className="px-1 pb-2"> N/A</span>
              )} */}
            </div>

            <div className="d-flex flex-row pb-2">
              <span className="min-width">
                <strong>Skills</strong>
              </span>
              <span>
                <strong>:</strong>
              </span>

              {courseDetails?.attributes?.skills &&
              courseDetails?.attributes?.skills?.data?.length > 0 ? (
                <div className="d-flex flex-column grid-connections px-2">
                  {chunk(
                    courseDetails?.attributes?.skills?.data?.sort((a, b) =>
                      a?.attributes?.["skill-title"]?.localeCompare(
                        b?.attributes?.["skill-title"]
                      )
                    ),
                    3
                  ).map((record, i) => {
                    return (
                      <div
                        className="d-flex mb-2 section-content-align"
                        key={i}
                      >
                        {record.map((elem, index) => {
                          return (
                            <div className="d-flex  grid-links" key={index}>
                              {/* <a
                                // href={`/landscape/finergy/learning`}
                                className="trainings-link fw-normal my-auto ms-1 pb-0"
                              >
                                {
                                  elem?.attributes?.skills?.data?.attributes?.[
                                    "skill-title"
                                  ]
                                }
                              </a> */}
                              <span
                                // href={`/landscape/finergy/learning`}
                                className="trainings-link fw-normal my-auto ms-1 pb-0"
                              >
                                {
                                  elem?.attributes?.skills?.data?.attributes?.[
                                    "skill-title"
                                  ]
                                }
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <span className="px-1 pb-2"> N/A</span>
              )}
            </div>

            <div className="d-flex flex-row pb-2">
              {" "}
              <span className="min-width">
                <strong>Curricula</strong>
              </span>
              <span>
                <strong>:</strong>
              </span>
              {courseDetails?.attributes?.["curricula"] &&
              courseDetails?.attributes?.["curricula"]?.data?.length > 0 ? (
                <div className="d-flex flex-column grid-connections px-2">
                  {chunk(
                    courseDetails?.attributes?.["curricula"]?.data?.sort(
                      (a, b) =>
                        a?.attributes?.["curricula-title"]?.localeCompare(
                          b?.attributes?.["curricula-title"]
                        )
                    ),
                    3
                  ).map((record, i) => {
                    return (
                      <div
                        className="d-flex mb-2 section-content-align"
                        key={i}
                      >
                        {record.map((elem, index) => {
                          return (
                            <div className="d-flex grid-links " key={index}>
                              {/* <a
                                // eslint-disable-next-line
                                // href={`/landscape/finergy/learning`}
                                className="trainings-link fw-normal my-auto ms-1 pb-0"
                              >
                                {
                                  elem?.attributes?.curricula?.data
                                    .attributes?.["curricula-title"]
                                }
                              </a> */}

                              <span
                                // eslint-disable-next-line
                                // href={`/landscape/finergy/learning`}
                                className="trainings-link fw-normal my-auto ms-1 pb-0"
                              >
                                {
                                  elem?.attributes?.curricula?.data
                                    .attributes?.["curricula-title"]
                                }
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <span className="px-1 pb-2"> N/A</span>
              )}
            </div>
          </div>

          <div className="fin-connections">
            {" "}
            <div className="d-flex flex-column mt-3">
              <h6>
                <b> Connections in FIN Landscape : </b>
              </h6>

              <div>
                <div className="button-group-container">
                  <ButtonGroup className="my-2 w-100">
                    <Button
                      className={
                        activeBtn === "apps"
                          ? "form-select-btn form-select-activeBtn w-25"
                          : "form-select-btn w-25"
                      }
                      onClick={() => handleConnections("apps")}
                    >
                      Applications
                    </Button>
                    <Button
                      className={
                        activeBtn === "tools"
                          ? "form-select-btn form-select-activeBtn w-25"
                          : "form-select-btn w-25"
                      }
                      onClick={() => handleConnections("tools")}
                    >
                      Tools
                    </Button>

                    <Button
                      className={
                        activeBtn === "proCons"
                          ? "form-select-btn form-select-activeBtn w-25"
                          : "form-select-btn w-25"
                      }
                      onClick={() => handleConnections("proCons")}
                    >
                      Processes
                    </Button>
                  </ButtonGroup>
                </div>

                <div className="banner-carousel">
                  <div className="connections-change-section mt-2">
                    {getFilteredData()?.length === 0 ? (
                      <div className="d-flex justify-content-center no-data-container">
                        <div className="d-flex flex-column mt-2">
                          <img
                            className="mx-auto no-data"
                            src={noConnections}
                            alt="No Data"
                          />

                          <p className="mb-0 fw-bold">No connections found!</p>
                        </div>
                      </div>
                    ) : (
                      <CarouselConnections data={chunk(getFilteredData(), 5)} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="custom-loader">
          <Loader />
        </div>
      )}

      {showDeleteModal && (
        <LearningDeleteModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          title={courseDetails?.attributes?.["courses-title"]}
          modalContentWithTitle={"Learning Title"}
          confimationMessage={"Are you sure you want to delete this Course?"}
          deleteAction={deleteCourse}
          updateStatus={props.updateStatus}
        />
      )}
      {showInfoModal && (
        <MyVerticallyCenteredModal
          show={showInfoModal}
          onHide={() => setShowInfoModal(false)}
          title="Course Description"
          content={courseDetails?.attributes?.["courses-description"]}
          data={readMoreData}
        />
      )}
    </div>
  );
}
