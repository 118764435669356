import { useState, useEffect, React } from "react";
import { Table } from "react-bootstrap";
import ImageSvgs from "../ImageSvgs";
import { ProcessDeleteModal } from "../../utils/processUtil";
import OwnerProfile from "../CommonComponents/OwnerProfile";
import { UserModal } from "../../utils/processUtil";
import { getData } from "../../services/apiService";
import { getHostUrl } from "../../config/environment";
import { Link } from "react-router-dom";
import ResponsivePagination from "react-responsive-pagination";
import { Typeahead } from "react-bootstrap-typeahead";
import { debounce } from "lodash";
import Loader from "../Loader/Loader";
import "../../assets/scss/common.scss";
import "./ProcessTable.scss";
import "react-bootstrap-typeahead/css/Typeahead.css";

export default function ProcessTable(props) {
  const periodOptions = [
    { id: 1, name: "Monthly" },
    { id: 2, name: "Quarterly" },
    { id: 3, name: "Annually" },
  ];

  const updateData = props.setFlag;
  const [processTableData, setProcessTableData] = useState([]);
  const editDataSet = props.setEditId;
  const viewData = props.setProcessViewData;
  const [showModal, setShowModal] = useState(false);
  const [deleteProcessData, setDeleteProcessData] = useState("");
  const [userModal, setShowUserModal] = useState(false);
  const [usersData, setUsersData] = useState();
  const [includedData, setInculdedData] = useState();
  const [nameQuery, setNameQuery] = useState("");
  const [descQuery, setDescQuery] = useState("");
  const [orgQuery, setOrgQuery] = useState([]);
  const [periodQuery, setPeriodQuery] = useState([]);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [loading, setLoading] = useState(false);
  const [orgOptions, setOrgOptions] = useState([]);
  const itemsPerPage = 20;

  useEffect(() => {
    debouncedGetResponse();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    debouncedGetResponse();

    return debouncedGetResponse.cancel;
    // eslint-disable-next-line
  }, [deleteFlag, nameQuery, descQuery, orgQuery, currentPage, periodQuery]);

  useEffect(() => {
    setCurrentPage(currentPage);
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    setNameQuery(nameQuery);
    // eslint-disable-next-line
  }, [nameQuery]);

  useEffect(() => {
    setDescQuery(descQuery);
    // eslint-disable-next-line
  }, [descQuery]);

  const processRes = async () => {
    setLoading(true);
    props.setTableLoad(false);

    const baseUrl = `${getHostUrl()}process/search`;

    const template =
      orgQuery?.length > 0 || nameQuery || periodQuery?.length > 0 || descQuery
        ? "process_search"
        : "process_template_table_view";

    const queryParams = [
      `template=${template}`,
      `include=owners,orgs`,
      `page=${currentPage}`,
      `per_page=${itemsPerPage}`,
      `use_pagination=true`,
    ];

    if (orgQuery?.length > 0) {
      queryParams.push(`org_id=${orgQuery[0]?.id}`);
    }
    if (nameQuery) {
      queryParams.push(`search=${nameQuery}`);
    }
    if (periodQuery?.length > 0) {
      queryParams.push(`periodicity_period=${periodQuery[0].id}`);
    }
    if (descQuery) {
      queryParams.push(`desc=${descQuery}`);
    }

    const endpoint = `${baseUrl}?${queryParams.join("&")}`;

    try {
      const res = await getData(endpoint);

      if (res?.status === 200) {
        setProcessTableData(res.data.data);
        setInculdedData(res.data.included);
        setLoading(false);
        if (res.data.meta) {
          setTotalPage(res.data.meta["page-count"]);
        }
      }
    } catch (error) {
      console.error("Error fetching process data:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedGetResponse = debounce(async () => {
    await processRes();
  }, 300);

  const setUsers = (list) => {
    let userList = [];
    // eslint-disable-next-line
    list.map((e) => {
      let newElem = includedData?.filter(
        (item) =>
          item.type === "owners" && item.id.toString() === e.id.toString()
      )[0];
      userList.push(newElem);
    });
    return userList;
  };
  const handleOrgSearch = async (query) => {
    const param = `${getHostUrl()}organization/search?search=${query}&org_chart_flag=${true}&per_page=20`;
    const res = await getData(param);
    setOrgOptions(res.data.data);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(1);
    setCurrentPage(newPage);
  };

  const handlePeriodChange = (value) => {
    setCurrentPage(1);
    setPeriodQuery(value);
  };

  const handleNameChange = (e) => {
    setCurrentPage(1);
    const { value } = e.target;
    setNameQuery(value);
  };

  const handleDescChange = (e) => {
    setCurrentPage(1);
    const { value } = e.target;
    setDescQuery(value);
  };

  const handleClick = (id, e) => {
    editDataSet(id);
    props.setLoadEditData(true);
    sessionStorage.setItem("updateId", id);
    props.setLoadEditData(true);
    sessionStorage.removeItem("processFlag");
    sessionStorage.removeItem("processConfig");
    sessionStorage.removeItem("processPayload");
  };

  return (
    <div>
      <div className="table-responsive">
        <Table className="main-table" striped>
          <thead className="user-table-head">
            <tr>
              <th>Process Name </th>
              <th>Process Description</th>
              <th>Process Periodicity </th>
              <th>Process Owners</th>
              <th>Department</th>
              <th>Action</th>
            </tr>
            <tr className="table-row">
              <th>
                <div className="app-primary-input-field mb-2">
                  <input
                    className="me-3 w-100"
                    type="search"
                    placeholder=" Search by name..."
                    value={nameQuery.current}
                    onChange={handleNameChange}
                  />
                </div>
              </th>
              <th>
                <div className="app-primary-input-field  mb-2">
                  <input
                    className="me-3 w-100"
                    type="search"
                    placeholder=" Search by description..."
                    value={descQuery.current}
                    onChange={handleDescChange}
                  />
                </div>
              </th>
              <th>
                <div className="app-primary-dropdown period-dropdown mb-2">
                  <Typeahead
                    multiple={false}
                    selected={periodQuery}
                    labelKey="name"
                    emptyLabel=""
                    placeholder="Search by periodicity..."
                    id="typeAhead"
                    clearButton
                    searchable
                    onChange={handlePeriodChange}
                    options={periodOptions}
                  />
                </div>
              </th>
              <th>
                <div className="app-primary-input-field  mb-2">
                  <input
                    disabled
                    className="me-3 w-100"
                    type="search"
                    placeholder="Disabled"
                  />
                </div>
              </th>
              <th>
                <div className="app-primary-dropdown org-dropdown mb-2">
                  <Typeahead
                    multiple={false}
                    labelKey="name"
                    filterBy={["name"]}
                    highlightOnlyResult={true}
                    clearButton={true}
                    placeholder="Search by department..."
                    id="typeAhead"
                    onChange={async (value) => {
                      setCurrentPage(1);
                      if (value?.length > 0) {
                        setOrgQuery(value);
                      } else {
                        setOrgQuery([]);
                      }
                    }}
                    onInputChange={(text) => {
                      handleOrgSearch(text);
                    }}
                    options={
                      orgOptions &&
                      orgOptions.map((item) => ({
                        type: "organization",
                        name: item.attributes["org-title"],
                        id: item.id,
                      }))
                    }
                  />
                </div>
              </th>
              <th>
                <div className="search-div disabled-dropdown mb-2">
                  <input
                    disabled
                    className="search-bar no-bg  owner-dropdown disabled-search-bar me-3 w-100"
                    type="search"
                    placeholder="Disabled"
                  />
                </div>
              </th>
            </tr>
          </thead>

          {loading === false ? (
            <tbody>
              {processTableData && processTableData.length > 0 ? (
                <>
                  {processTableData &&
                    processTableData
                      ?.sort((a, b) =>
                        a.attributes["title"].localeCompare(
                          b.attributes["title"]
                        )
                      )
                      ?.map((data) => (
                        <tr key={data.id}>
                          <td className="my-2 app-table-name">
                            <span
                              className="app-table-name"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Visit Process"
                              onClick={() => {
                                viewData(data);
                              }}>
                              <Link
                                className="app-table-name process-name"
                                to={`/landscape/process?process_id=${data.id}&view=cardView`}
                                state={{ prevPath: window.location.href }}>
                                {data.attributes.title}
                              </Link>
                            </span>
                          </td>
                          <td className="my-2">{data.attributes.desc}</td>
                          <td className="my-2">
                            {data?.attributes["periodicity-period"] === 1
                              ? "Monthly"
                              : data?.attributes["periodicity-period"] === 2
                              ? "Quarterly"
                              : data?.attributes["periodicity-period"] === 3
                              ? "Annually"
                              : "-"}
                          </td>

                          <td className="my-1">
                            <span>
                              {" "}
                              {data?.relationships?.owners?.data.length > 0 ? (
                                <>
                                  {data?.relationships?.owners?.data
                                    .slice(0, 3)
                                    .map((elem, index) => {
                                      let owner = includedData?.filter(
                                        (item) =>
                                          item.type === "owners" &&
                                          item.id.toString() ===
                                            elem.id.toString()
                                      )[0];

                                      return (
                                        <OwnerProfile
                                          key={index}
                                          owner={owner}
                                          isOwnerHasLink={true}
                                        />
                                      );
                                    })}
                                  {data?.relationships?.owners?.data.length >
                                  3 ? (
                                    <>
                                      <button
                                        className="more-user-btn"
                                        onClick={async () => {
                                          let userList = await setUsers(
                                            data?.relationships?.owners?.data
                                          );

                                          setUsersData(userList);
                                          setShowUserModal(true);
                                        }}>
                                        +{" "}
                                        {data?.relationships?.owners?.data
                                          .length - 3}{" "}
                                        more
                                      </button>
                                    </>
                                  ) : null}
                                </>
                              ) : (
                                <b>-</b>
                              )}
                            </span>
                          </td>

                          <td className="my-2">
                            {" "}
                            {data?.relationships?.orgs?.data.length > 0 ? (
                              <>
                                {data?.relationships?.orgs?.data.map(
                                  (elem, index) => {
                                    let org = includedData?.filter(
                                      (item) =>
                                        item.type === "orgs" &&
                                        item.id.toString() ===
                                          elem.id.toString()
                                    )[0]?.attributes["org-master"].data;

                                    if (
                                      index >=
                                      data?.relationships?.orgs?.data.length - 1
                                    ) {
                                      return (
                                        <span>
                                          {org?.attributes["org-title"]}
                                        </span>
                                      );
                                    } else {
                                      return (
                                        <span>
                                          {org?.attributes["org-title"]} ,{" "}
                                        </span>
                                      );
                                    }
                                  }
                                )}
                              </>
                            ) : (
                              <b>-</b>
                            )}
                          </td>
                          <td className="my-2">
                            <div className="d-flex flex-row">
                              <div
                                className="cursor-pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleClick(data.id);
                                }}>
                                <ImageSvgs name="edit" />
                              </div>
                              <span
                                className="px-4 cursor-pointer"
                                onClick={() => {
                                  setShowModal(true);
                                  setDeleteProcessData(data);
                                }}>
                                <ImageSvgs name="delete" />
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                </>
              ) : (
                <div className="w-100 empty-data-check">
                  <div className="w-100 mt-2 no-data-table-view">
                    <p className="fw-bold mt-1 mb-0">
                      No matching records found.
                    </p>
                  </div>
                </div>
              )}
            </tbody>
          ) : (
            <div className="process-custom-loader">
              <Loader />
            </div>
          )}
        </Table>

        {showModal === true ? (
          <ProcessDeleteModal
            deleteProcessData={deleteProcessData}
            show={showModal}
            onHide={() => setShowModal(false)}
            updateData={updateData}
            setDeleteFlag={setDeleteFlag}
            deleteFlag={deleteFlag}
            setTableLoad={props.setTableLoad}
          />
        ) : (
          ""
        )}
        {userModal === true ? (
          <UserModal
            title={"Process Owners"}
            heading={"List of Process Owners"}
            listdata={usersData}
            show={userModal}
            onHide={() => setShowUserModal(false)}
          />
        ) : (
          ""
        )}
      </div>
      <div className="w-100 mt-3  pb-2  d-flex justify-content-end">
        <ResponsivePagination
          current={currentPage}
          total={totalPage}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
}
