import React, { useState, useEffect } from "react";
import TanstackReactFinTable from "../CommonComponents/TanstackReactFCTable/TanstackReactFinTable";
import ResponsivePagination from "react-responsive-pagination";
import { Button } from "react-bootstrap";
import ImageSvgs from "../ImageSvgs";
import { Nav } from "react-bootstrap";
import { getHostUrl } from "../../config/environment";
import { getData, deleteData } from "../../services/apiService";
import "./FinergyLearningAdminPage.scss";
import { useNavigate } from "react-router-dom";
import OwnerProfile from "../CommonComponents/OwnerProfile";
import Loader from "../Loader/Loader";
import LearningDeleteModal from "./LearningDeletModal/LearningDeleteModal";
import { UserModal } from "../../utils/toolsUtils";

const FinergyLearningAdminPage = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [learnings, setLearnings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [recordCount, setRecordCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [courseTitle, setCourseTitle] = useState(null);
  const [courseId, setCourseId] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [ownerData, setOwnerData] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const response = await getData(
          `${getHostUrl()}courses?page=${currentPage}&per_page=10`
        );
        if (response.status === 200) {
          setLearnings(response.data.data);
          setTotalPages(response.data.meta["page-count"]);
          setRecordCount(response.data.meta["record-count"]);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, isDeleted]);

  useEffect(() => {
    const titleSearch =
      columnFilters.length > 0
        ? columnFilters.find((filter) => filter.id === "title").value
        : "";

    async function fetchData() {
      // setLoading(true);
      try {
        const response = await getData(
          `${getHostUrl()}courses/search?search=${titleSearch}&page=${currentPage}&per_page=10`
        );
        if (response.status === 200) {
          setLearnings(response.data.data);
          setTotalPages(response.data.meta["page-count"]);
          setRecordCount(response.data.meta["record-count"]);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnFilters]);

  const handleEdit = (row) => {
    navigate(`/manage_learnings/edit_learning/${row.id}`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "attributes.courses-title",
        id: "title",
        cell: (info) => <div className="title-cell">{info.getValue()}</div>,
        header: () => <span>Title</span>,
        footer: (props) => props.column.id,
        size: 300,
        enableColumnFilter: false,
      },
      {
        accessorKey: "attributes.owners.data",
        enableColumnFilter: false,
        id: "owners",
        cell: (info) => {
          const owners = info.getValue();
          if (!owners || owners.length === 0) {
            return "-";
          }
          return (
            <div className="owners-cell">
              {owners.slice(0, 3).map((owner, index) => (
                <OwnerProfile key={index} owner={owner} isOwnerHasLink={true} />
              ))}
              {owners?.length > 3 ? (
                <button
                  className="more-user-btn"
                  onClick={async (e) => {
                    e.stopPropagation();
                    setOwnerData(owners);
                    setShowUserModal(true);
                  }}>
                  + {owners?.length - 3} more
                </button>
              ) : null}
            </div>
          );
        },
        header: () => <span>Owners</span>,
        footer: (props) => props.column.id,
        size: 300,
      },
      {
        accessorKey: "attributes.created-by",
        id: "source",
        cell: (info) => (
          <div className="source-cell">
            {info.getValue() === "system" ? "SELP" : "SEFC"}
          </div>
        ),
        header: () => <span>Source</span>,
        footer: (props) => props.column.id,
        size: 200,
        enableColumnFilter: false,
      },
      {
        id: "actions",
        header: () => <span>Actions</span>,
        cell: ({ row }) => {
          const isSEFC = row.original.attributes["created-by"] === "system";

          return (
            <div className="actions-cell">
              <span
                className="edit-icon"
                data-testid="edit-icon"
                onClick={() => handleEdit(row.original)}>
                <ImageSvgs name="edit" />
              </span>
              <span className={isSEFC ? "disabled-icon" : ""}>
                <span
                  data-testid="delete-icon"
                  className="delete-icon"
                  onClick={() => !isSEFC && handleDelete(row.original)}>
                  <ImageSvgs name="delete" />
                </span>
              </span>
            </div>
          );
        },
        footer: (props) => props.column.id,
        size: 100,
        enableColumnFilter: false,
      },
    ],
    // eslint-disable-next-line
    []
  );

  const deleteCourse = async () => {
    try {
      const res = await deleteData(`${getHostUrl()}courses/${courseId}`);
      if (res.status === 204) {
        setShowDeleteModal(false);
        setIsDeleted(!isDeleted);
      }
    } catch (error) {
      console.error("Error deleting the course: ", error);
    }
  };

  const handleDelete = (row) => {
    setShowDeleteModal(true);
    setCourseTitle(row.attributes["courses-title"]);
    setCourseId(row.id);
  };

  const onRowClick = (row) => {
    // Implement your row click logic here
  };

  return (
    <>
      <div className="p-5">
        <div className="responsive-container">
          <div className="back-icon d-flex">
            <Nav.Link onClick={() => navigate(-1)} data-testid="back-button">
              <ImageSvgs name="goBack" />
            </Nav.Link>
            <span className="ps-1"> Back </span>
          </div>

          <div className="sub-bar d-flex justify-content-between">
            <p className="main-title" data-testid="main-title">
              Manage Learnings ({recordCount})
            </p>
            <div className="search-div d-flex">
              <Button
                className="app-secondary-btn"
                onClick={() => {
                  navigate("/manage_learnings/add_learning");
                }}
                data-testid="add-button">
                Add New Learning
              </Button>
            </div>
          </div>
          <div data-testid="learning-table">
            {loading ? (
              <div className="learning-admin-loader">
                <Loader />
              </div>
            ) : (
              <TanstackReactFinTable
                data={learnings}
                columns={columns}
                onRowClick={(row) => onRowClick(row.original)}
                isManualPagination={true}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                // columnsFilteredData={handleColumnsFilteredData}
              />
            )}
          </div>
        </div>

        {!loading && (
          <div
            className="w-100 mt-5 pb-2 d-flex justify-content-end"
            data-testid="pagination">
            <ResponsivePagination
              current={currentPage}
              total={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        )}
        {showDeleteModal && (
          <LearningDeleteModal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
            title={courseTitle}
            modalContentWithTitle={"Learning Title"}
            confimationMessage={"Are you sure you want to delete this Course?"}
            deleteAction={deleteCourse}
          />
        )}
        {showUserModal === true ? (
          <UserModal
            title={"Learning Owners:-"}
            listdata={ownerData}
            show={showUserModal}
            onHide={() => setShowUserModal(false)}
          />
        ) : null}
      </div>
    </>
  );
};

export default FinergyLearningAdminPage;
