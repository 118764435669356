import React, { useState, useEffect } from "react";
import { List, ListItemButton, ListItemText, Collapse } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { withStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";

const styles = (theme) => ({
  listItem: {
    "&,&:focus": {
      backgroundColor: theme.palette.background.paper,
    },
    border: "none",
    padding: "5px",
  },
  important: {
    color: "rgb(138, 0, 229)",
    fontWeight: "bold",
  },
});

const resetImportantProperty = (data, hierarchy) => {
  return data.map((item) => ({
    ...item,
    important: false,
    children:
      hierarchy?.filter(
        (elem) => elem?.attributes?.["parent-hierarchy"]?.data?.id === item?.id
      ).length > 0
        ? resetImportantProperty(
            hierarchy?.filter(
              (elem) =>
                elem?.attributes?.["parent-hierarchy"]?.data?.id === item?.id
            ),
            hierarchy
          )
        : [],
  }));
};

const updateImportantProperty = (data, currentItem, hierarchy) => {
  return data.map((item) => {
    if (item?.id === currentItem?.id) {
      return {
        ...item,
        important: true,
        children: item.children
          ? resetImportantProperty(item.children, hierarchy)
          : [],
      };
    } else {
      return {
        ...item,
        important: false,
        children: item.children
          ? updateImportantProperty(item.children, currentItem, hierarchy)
          : [],
      };
    }
  });
};

const NestedList = ({ items, classes, hierarchy, setParentsId, parentsId }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState({});
  const [listData, setListData] = useState(
    resetImportantProperty(items, hierarchy)
  );

  useEffect(() => {
    setListData(resetImportantProperty(items, hierarchy));
    toggleOpen(
      hierarchy?.filter(
        (elem) =>
          elem?.attributes?.["type-data"]?.data?.attributes?.[
            "type-field-name"
          ] === "All Trainings"
      )[0]
    );
    // eslint-disable-next-line
  }, []);

  const toggleOpen = (item) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [item?.attributes?.["is-leaf"] === true
        ? item?.attributes?.skills?.data?.attributes?.["skill-title"]
        : item?.attributes?.["is-leaf"] === false &&
          item?.attributes?.["type-data"]?.data?.attributes?.type ===
            "FinanceField"
        ? item?.attributes?.["type-data"]?.data?.attributes?.[
            "finance-fields-data"
          ]?.data?.attributes?.["finance-fields-title"]
        : item?.attributes?.["type-data"]?.data?.attributes?.[
            "type-field-name"
          ]]:
        !prevOpen[
          item?.attributes?.["is-leaf"] === true
            ? item?.attributes?.skills?.data?.attributes?.["skill-title"]
            : item?.attributes?.["is-leaf"] === false &&
              item?.attributes?.["type-data"]?.data?.attributes?.type ===
                "FinanceField"
            ? item?.attributes?.["type-data"]?.data?.attributes?.[
                "finance-fields-data"
              ]?.data?.attributes?.["finance-fields-title"]
            : item?.attributes?.["type-data"]?.data?.attributes?.[
                "type-field-name"
              ]
        ],
    }));
  };

  const handleExpandClick = (e, item) => {
    e.stopPropagation();
    toggleOpen(item);
  };

  const handleItemClick = async (item) => {
    navigate(`/landscape/finergy/learning/${item?.id}`);
    setListData((prevData) =>
      updateImportantProperty(prevData, item, hierarchy)
    );
  };

  return (
    <List component="nav" aria-labelledby="nested-list-subheader">
      {listData
        ?.sort((a, b) =>
          (a?.attributes?.["is-leaf"] === true
            ? a?.attributes?.skills?.data?.attributes?.["skill-title"]
            : a?.attributes?.["is-leaf"] === false &&
              a?.attributes?.["type-data"]?.data?.attributes?.type ===
                "FinanceField"
            ? a?.attributes?.["type-data"]?.data?.attributes?.[
                "finance-fields-data"
              ]?.data?.attributes?.["finance-fields-title"]
            : a?.attributes?.["type-data"]?.data?.attributes?.[
                "type-field-name"
              ]
          ).localeCompare(
            b?.attributes?.["is-leaf"] === true
              ? b?.attributes?.skills?.data?.attributes?.["skill-title"]
              : b?.attributes?.["is-leaf"] === false &&
                b?.attributes?.["type-data"]?.data?.attributes?.type ===
                  "FinanceField"
              ? b?.attributes?.["type-data"]?.data?.attributes?.[
                  "finance-fields-data"
                ]?.data?.attributes?.["finance-fields-title"]
              : b?.attributes?.["type-data"]?.data?.attributes?.[
                  "type-field-name"
                ]
          )
        )
        ?.map((item) => (
          <React.Fragment key={item?.id}>
            <ListItemButton
              onClick={(e) => {
                setParentsId(item?.id);
                handleItemClick(item);
              }}
              className={classes.listItem}
              divider
            >
              <ListItemText
                primary={
                  item?.attributes?.["is-leaf"] === true
                    ? item?.attributes?.skills?.data?.attributes?.[
                        "skill-title"
                      ]
                    : item?.attributes?.["is-leaf"] === false &&
                      item?.attributes?.["type-data"]?.data?.attributes
                        ?.type === "FinanceField"
                    ? item?.attributes?.["type-data"]?.data?.attributes?.[
                        "finance-fields-data"
                      ]?.data?.attributes?.["finance-fields-title"]
                    : item?.attributes?.["type-data"]?.data?.attributes?.[
                        "type-field-name"
                      ]
                }
                classes={{
                  primary:
                    parentsId?.toString() === item?.id?.toString()
                      ? classes.important
                      : "",
                }}
              />
              {item.children && item.children.length > 0 && (
                <div onClick={(e) => handleExpandClick(e, item)}>
                  {open[
                    item?.attributes?.["is-leaf"] === true
                      ? item?.attributes?.skills?.data?.attributes?.[
                          "skill-title"
                        ]
                      : item?.attributes?.["is-leaf"] === false &&
                        item?.attributes?.["type-data"]?.data?.attributes
                          ?.type === "FinanceField"
                      ? item?.attributes?.["type-data"]?.data?.attributes?.[
                          "finance-fields-data"
                        ]?.data?.attributes?.["finance-fields-title"]
                      : item?.attributes?.["type-data"]?.data?.attributes?.[
                          "type-field-name"
                        ]
                  ] ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </div>
              )}
            </ListItemButton>
            {item.children && item.children.length > 0 && (
              <Collapse
                in={
                  open[
                    item?.attributes?.["is-leaf"] === true
                      ? item?.attributes?.skills?.data?.attributes?.[
                          "skill-title"
                        ]
                      : item?.attributes?.["is-leaf"] === false &&
                        item?.attributes?.["type-data"]?.data?.attributes
                          ?.type === "FinanceField"
                      ? item?.attributes?.["type-data"]?.data?.attributes?.[
                          "finance-fields-data"
                        ]?.data?.attributes?.["finance-fields-title"]
                      : item?.attributes?.["type-data"]?.data?.attributes?.[
                          "type-field-name"
                        ]
                  ]
                }
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding sx={{ pl: 1, pt: 1 }}>
                  <NestedList
                    items={item.children}
                    classes={classes}
                    hierarchy={hierarchy}
                    setParentsId={setParentsId}
                    parentsId={parentsId}
                  />
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
    </List>
  );
};

const FinNestedList = withStyles(styles)(
  ({ classes, hierarchy, setParentsId, setList, parentsId }) => {
    return (
      <NestedList
        items={hierarchy?.filter((elem) => elem?.id === 1)}
        classes={classes}
        hierarchy={hierarchy}
        setParentsId={setParentsId}
        parentsId={parentsId}
        setList={setList}
      />
    );
  }
);

export default FinNestedList;
