import React from "react";
import TanstackReactFinTable from "../CommonComponents/TanstackReactFCTable/TanstackReactFinTable";
import { Button, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import ImageSvgs from "../ImageSvgs";
import { Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getHostUrl } from "../../config/environment";
import { getData, deleteData } from "../../services/apiService";
import Loader from "../Loader/Loader";
import ResponsivePagination from "react-responsive-pagination";
import "./FinanceFieldAdminPage.scss";

const FinanceFieldAdminPage = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const columns = React.useMemo(
    () => [
      {
        accessorKey: "attributes.finance-fields-title",
        id: "title",
        cell: (info) => info.getValue(),
        header: () => <span>Title</span>,
        footer: (props) => props.column.id,
        enableColumnFilter: false,
        size: 700,
      },
      {
        id: "actions",
        header: () => <span>Actions</span>,
        cell: ({ row }) => (
          <div>
            <span
              className="edit-icon"
              data-testid="edit-icon"
              onClick={() => handleEdit(row.original)}>
              <ImageSvgs name="edit" />
            </span>

            <span
              data-testid="delete-icon"
              className="delete-icon"
              onClick={() => handleDelete(row.original)}>
              <ImageSvgs name="delete" />
            </span>
          </div>
        ),
        footer: (props) => props.column.id,
        size: 100,
      },
    ],
    // eslint-disable-next-line
    []
  );

  const [data, setData] = useState([]);
  const [deleteField, setDeleteField] = useState();
  const [showModal, setShowModal] = useState(false);
  const [flag, setFlag] = useState(true);

  useEffect(() => {
    (async () => {
      if (flag === true) {
        let res = await getData(
          `${getHostUrl()}financefields?use_pagination=true&page=${currentPage}&per_page=20`
        );
        await setData(
          res?.data?.data?.sort((a, b) =>
            a.attributes["finance-fields-title"].localeCompare(
              b.attributes["finance-fields-title"]
            )
          )
        );
        await setTotalPages(res?.data?.meta?.["page-count"]);
        setFlag(false);
      }
    })();
  }, [flag, currentPage]);

  const handleEdit = (row) => {
    // Implement your edit logic here
    navigate(`/manage_fields/edit_field/${row?.id}`);
  };

  const handleDelete = (row) => {
    // Implement your delete logic here
    setDeleteField(row);
    setShowModal(true);
  };

  const onRowClick = (row) => {
    // Implement your row click logic here
    // console.log("Row clicked:", row);
  };

  const handlePageChange = (page) => {
    setFlag(true);
    setCurrentPage(page);
  };

  const DeleteFieldModal = (props) => {
    const [loading, setLoading] = useState(false);

    const handleDeleteField = async (id) => {
      const headers = {
        Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
      };
      try {
        setLoading(true);
        // eslint-disable-next-line
        const res = await deleteData(
          `${getHostUrl()}financefields/${id}`,
          headers
        );
        setLoading(false);
        props.onHide();
        setFlag(true);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete Finance Field
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            Name: {props?.deleteField?.attributes["finance-fields-title"]}
          </h5>
          <p>Are you sure you want to delete this field?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="app-secondary-btn" onClick={props.onHide}>
            Cancel
          </Button>
          <Button
            className="app-primary-btn"
            onClick={() => {
              handleDeleteField(props?.deleteField?.id);
            }}>
            {loading ? "Deleting..." : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      {data?.length > 0 ? (
        <div className="pt-5 pe-5 ps-5 pb-3">
          <div className="responsive-container">
            <div className="back-icon d-flex">
              <Nav.Link onClick={() => navigate(-1)} data-testid="back-button">
                {" "}
                <ImageSvgs name="goBack" />
              </Nav.Link>
              <span className="ps-1"> Back </span>
            </div>

            <div className="sub-bar d-flex justify-content-between">
              <p className="main-title" data-testid="main-title">
                Manage Finance Fields({data?.length})
              </p>
              <div className="search-div d-flex">
                <Button
                  className="app-secondary-btn"
                  onClick={() => {
                    navigate("/manage_fields/add_field");
                  }}
                  data-testid="add-button">
                  Add New Finance Field
                </Button>
              </div>
            </div>
            <div className="table-wrapper">
              <TanstackReactFinTable
                {...{
                  data,
                  columns,
                  onRowClick: (row) => ({
                    onClick: () => onRowClick(row.original),
                  }),
                }}
                data-testid="fin-table"
              />
            </div>

            <footer className="footer mt-4">
              <div className="d-flex justify-content-end">
                <ResponsivePagination
                  current={currentPage}
                  total={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </footer>
          </div>
        </div>
      ) : (
        <div className="custom-loader">
          <Loader />
        </div>
      )}
      {showModal ? (
        <DeleteFieldModal
          deleteField={deleteField}
          show={showModal}
          onHide={() => setShowModal(false)}
        />
      ) : null}
    </>
  );
};
export default FinanceFieldAdminPage;
