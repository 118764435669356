import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

export const useQueryParams = (dropdowns) => {
  const location = useLocation();
  const navigate = useNavigate();

  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    const filters = {};
    dropdowns.forEach((dropdown) => {
      const selectedValue = params.get(dropdown.queryKey);
      if (selectedValue) {
        filters[dropdown.queryKey] = JSON.parse(
          decodeURIComponent(selectedValue)
        );
      }
    });
    return filters;
  };

  const [filters, setFilters] = useState(getQueryParams());

  useEffect(() => {
    setFilters(getQueryParams());
    // eslint-disable-next-line
  }, [location]);

  const setFiltersInUrl = (newFilters) => {
    const params = new URLSearchParams(location.search);

    Object.keys(newFilters).forEach((key) => {
      const value = newFilters[key];

      if (Array.isArray(value) && value.length > 0) {
        params.set(key, encodeURIComponent(JSON.stringify(value)));
      } else if (value !== undefined && value !== null) {
        params.set(key, encodeURIComponent(JSON.stringify(value)));
      } else {
        params.delete(key);
      }
    });

    navigate({ search: params.toString() });
  };

  return {
    filters,
    setFilters: setFiltersInUrl,
  };
};
