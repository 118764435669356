import React from "react";
import { Route } from "react-router-dom";
import FinergyDashboard from "../components/FinergyComponents/FinergyDashboard";
import FinergyLearningPage from "../components/FinergyComponents/FinergyLearningPage";
import FinergyOverviewPage from "../components/FinergyComponents/FinergyOverviewPage";
import FinanceFieldAdminPage from "../components/FinergyComponents/FinanceFieldAdminPage";
import AddEditFinanceField from "../components/FinergyComponents/AddEditFinanceField";
import FinergyLearningAdminPage from "../components/FinergyComponents/FinergyLearningAdminPage";
import AddEditFinergyLearning from "../components/FinergyComponents/AddEditFinergyLearning";

const FinergyRoutes = () => {
  return (
    <React.Fragment>
      <Route path="/landscape/finergy" element={<FinergyDashboard />}>
        <Route path="overview" element={<FinergyOverviewPage />} />
        <Route path="learning/:parentId" element={<FinergyLearningPage />} />
        <Route
          path="learning/:parentId?viewId=:learningId"
          element={<FinergyLearningPage />}
        />
      </Route>
      <Route path="/manage_fields" element={<FinanceFieldAdminPage />} />
      <Route
        path="/manage_fields/add_field"
        element={<AddEditFinanceField />}
      />
      <Route
        path="/manage_fields/edit_field/:fieldId"
        element={<AddEditFinanceField />}
      />
      <Route path="/manage_learnings" element={<FinergyLearningAdminPage />} />
      <Route
        path="/manage_learnings/add_learning"
        element={<AddEditFinergyLearning />}
      />
      <Route
        path="/manage_learnings/edit_learning/:fieldId"
        element={<AddEditFinergyLearning />}
      />
    </React.Fragment>
  );
};

export default FinergyRoutes;
