import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { MultipleSelectDropdown } from "../../components/CommonComponents/MultipleSelect";
import ImageSvgs from "../ImageSvgs";
import { useNavigate, useParams } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { ImageUpload } from "../AdminComponents/ImageUpload";
import { getHostUrl } from "../../config/environment";
import { getData, postData, patchData } from "../../services/apiService";
import { EmployeeModal } from "../CommonComponents/EmployeeModal";
import { now } from "lodash";
import Loader from "../Loader/Loader";
import { getBase64 } from "../../utils/favoriteUtils";
import "./AddEditFinergyLearning.scss";

const validateFields = (title, description, url) => {
  const errors = {
    titleCheck: title.length === 0,
    descriptionCheck: description.length === 0,
    urlCheck: url.length === 0,
  };
  return errors;
};

const AddEditFinergyLearning = ({ setLevel }) => {
  const navigate = useNavigate();
  const params = useParams();
  const fieldId = params ? params?.fieldId : null;
  const [title, setTitle] = useState("");
  const [titleCheck, setTitleCheck] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionCheck, setDescriptionCheck] = useState(false);
  const [urlCheck, setUrlCheck] = useState(false);
  const [url, setUrl] = useState("");
  const [owners, setOwners] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [apps, setApps] = useState([]);
  const [tools, setTools] = useState([]);
  const [skills, setSkills] = useState([]);
  const [process, setProcess] = useState([]);
  const [curriculaOptions, setCurriculaOptions] = useState([]);
  const [courseImage, setCourseImage] = useState("");
  const [loading, setLoading] = useState(true);
  const [isDeleted, setIsDeleted] = useState(false);

  const [selectedApps, setSelectedApps] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState([]);
  const [selectedTools, setSelectedTools] = useState([]);
  const [isCreatedBy, setIsCreatedBy] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (isDeleted) {
      setCourseImage(null);
      setIsDeleted(false);
    }
  }, [isDeleted]);

  useEffect(() => {
    (async () => {
      let dataRes;
      setLoading(true);
      if (fieldId) {
        dataRes = await getData(`${getHostUrl()}courses/${fieldId && fieldId}`);
        await setTitle(dataRes?.data?.data?.attributes?.["courses-title"]);
        await setDescription(
          dataRes?.data?.data.attributes["courses-description"]
        );
        await setUrl(dataRes?.data?.data.attributes["courses-redirect-url"]);
        const selectedOwners = dataRes?.data?.data.attributes.owners.data
          .filter((item) => item?.id)
          .map((elem) => {
            return {
              type: "owner",
              name: elem?.attributes?.employee?.data?.attributes[
                "preferred-name"
              ],
              attributes: {
                owner_id: elem?.id.toString(),
                "employee-id": elem?.attributes?.employee?.data?.id.toString(),
              },
              id: null,
            };
          });
        await setOwners(selectedOwners);
        await setCourseImage(dataRes?.data?.data.attributes["course-image"]);
        const isCreatedBy =
          dataRes?.data?.data.attributes["created-by"] === "system"
            ? "true"
            : "false";
        await setIsCreatedBy(isCreatedBy);
      }

      let toolsRes = await getData(`${getHostUrl()}tools?use_pagination=false`);
      const toolsOptions = toolsRes?.data?.data?.map((item) => {
        return {
          type: "tool",
          name: `${item.attributes["tool-title"]}`,
          attributes: {
            tool_id: item.id.toString(),
          },
          id: null,
        };
      });
      setTools(toolsOptions);

      setSelectedTools(
        dataRes?.data?.data?.attributes["generic-tools-master"]?.data.filter(
          (item) => item?.id
        ).length > 0
          ? dataRes?.data?.data?.attributes["generic-tools-master"]?.data
              ?.filter((item) => item?.id)
              ?.map((elem) => {
                return {
                  type: "tool",
                  name: elem?.attributes?.["tool-title"],
                  attributes: {
                    tool_id: elem?.id.toString(),
                  },
                  id: null,
                };
              })
          : []
      );

      let appsRes = await getData(
        `${getHostUrl()}application?use_pagination=false`
      );
      const appData = appsRes?.data?.data?.map((item) => {
        return {
          type: "application",
          name: item.attributes["app-title"],
          attributes: {
            application_id: item.id ? item.id.toString() : "",
          },
          id: null,
        };
      });
      setApps(appData);
      setSelectedApps(
        dataRes?.data?.data?.attributes["generic-apps"]?.data.filter(
          (item) => item?.id
        ).length > 0
          ? dataRes?.data?.data?.attributes["generic-apps"]?.data
              ?.filter((item) => item?.id)
              ?.map((elem) => {
                return {
                  type: "application",
                  name: elem?.attributes?.["app-title"],
                  attributes: {
                    application_id: elem?.id.toString(),
                  },
                  id: null,
                };
              })
          : []
      );

      let processRes = await getData(
        `${getHostUrl()}process?use_pagination=false`
      );
      const processData = processRes?.data?.data?.map((item) => {
        return {
          type: "process",
          name: item.attributes["title"],
          attributes: {
            process_id: item.id ? item.id.toString() : "",
          },
          id: null,
        };
      });
      setProcess(processData);

      setSelectedProcess(
        dataRes?.data?.data?.attributes["generic-process"]?.data.filter(
          (item) => item?.id
        ).length > 0
          ? dataRes?.data?.data?.attributes["generic-process"]?.data
              ?.filter((item) => item?.id)
              ?.map((elem) => {
                return {
                  type: "process",
                  name: elem?.attributes?.title,
                  attributes: {
                    process_id: elem?.id.toString(),
                  },
                  id: null,
                };
              })
          : []
      );
      setSkills(
        dataRes?.data?.data?.attributes["mapping_skills_data"]?.filter(
          (item) => item?.id
        ).length > 0
          ? dataRes?.data?.data?.attributes["mapping_skills_data"]
              ?.filter((item) => item?.id)
              ?.map((elem) => {
                return {
                  type: "skills",
                  name: elem?.attributes?.["skill-title"],
                  attributes: {
                    skills_id: elem?.id.toString(),
                  },
                  id: null,
                };
              })
          : []
      );

      setCurriculaOptions(
        dataRes?.data?.data?.attributes["mapping_curricula_data"]?.filter(
          (item) => item?.id
        ).length > 0
          ? dataRes?.data?.data?.attributes["mapping_curricula_data"]
              ?.filter((item) => item?.id)
              ?.map((elem) => {
                return {
                  type: "curricula",
                  name: elem?.attributes?.["curricula-title"],
                  attributes: {
                    curricula_id: elem?.id.toString(),
                  },
                  id: null,
                };
              })
          : []
      );
      setLoading(false);
    })();
    // eslint-disable-next-line
  }, []);

  let payload = {
    data: {
      type: "courses",
      attributes: {
        course_image: courseImage ? courseImage : "",
        courses_redirect_url: url,
        courses_title: title,
        courses_description: description,
      },
      relationships: {
        apps: {
          data: selectedApps,
        },
        curricula: {
          data: curriculaOptions,
        },
        skills: {
          data: skills,
        },
        owners: {
          data: owners,
        },
        process: {
          data: selectedProcess,
        },
        tools_master: {
          data: selectedTools,
        },
      },
    },
  };

  const handleImageUpload = async (payload) => {
    const courseImg = payload["data"]["attributes"]["course_image"];
    const hash = require("object-hash");

    const processImage = async (image) => {
      const base64Img = await getBase64(image);
      const base64 = base64Img.split(",")?.pop();
      const imageHash = hash({ file: image, timestamp: now() });
      const extension = image.type?.split("/")?.pop();
      const sendImageUrl = `/configurations/FiNergy/images/courses/${imageHash}.${extension}`;
      return { base64, sendImageUrl };
    };

    let base64 = null;
    let sendImageUrl = null;

    if (fieldId) {
      if (courseImg !== null && typeof courseImg === "string") {
        sendImageUrl = courseImg;
      } else if (courseImg !== null && typeof courseImg === "object") {
        const result = await processImage(courseImg);
        base64 = result.base64;
        sendImageUrl = result.sendImageUrl;
      }
    } else {
      if (courseImg) {
        const result = await processImage(courseImg);
        base64 = result.base64;
        sendImageUrl = result.sendImageUrl;
      }
    }

    const newPayload = {
      ...payload,
      data: {
        ...payload.data,
        attributes: {
          ...payload.data.attributes,
          image: base64,
          course_image: courseImg ? sendImageUrl : null,
        },
      },
    };

    return newPayload;
  };

  let buildUrl = fieldId
    ? `${getHostUrl()}courses/${fieldId}`
    : `${getHostUrl()}courses`;

  const handleAppChange = (value) => {
    setSelectedApps(value);
  };
  const handleProcessChange = (value) => {
    setSelectedProcess(value);
  };
  const handleToolChange = (value) => {
    setSelectedTools(value);
  };
  const saveLearnings = async () => {
    const newPayloadPost = await handleImageUpload(payload);
    setLoading(true);
    try {
      const response = fieldId
        ? await patchData(buildUrl, newPayloadPost)
        : await postData(buildUrl, newPayloadPost);
      if (response.status === 200) {
        navigate(-1);
      }
    } catch (error) {
      console.log("Error while saving data: ", error);
    } finally {
      setLoading(false);
    }
  };
  const handleSave = () => {
    const errors = validateFields(title, description, url);
    setTitleCheck(errors.titleCheck);
    setDescriptionCheck(errors.descriptionCheck);
    setUrlCheck(errors.urlCheck);

    if (!errors.titleCheck && !errors.descriptionCheck && !errors.urlCheck) {
      saveLearnings();
    }
  };

  return (
    <>
      <div className="p-5">
        <div className="back-icon d-flex">
          <Nav.Link onClick={() => navigate(-1)} data-testid="back-button">
            {" "}
            <ImageSvgs name="goBack" />
          </Nav.Link>
          <span className="ps-1 fw-bold"> Back </span>
        </div>
        <h4 className="fw-bold mt-2">
          {fieldId ? "Edit Learning" : "Add New Learning"}
        </h4>
        {loading ? (
          <div className="custom-loader">
            <Loader />
          </div>
        ) : (
          <Form noValidate className="tool-form">
            {/* <Row>
            <Form.Group className="d-flex pt-2 pe-2">
              <Form.Label> Choose Learning Type:</Form.Label>
              <div id="app-primary-radio">
                <Form.Check
                  type="radio"
                  id="0"
                  className="ms-2 me-3"
                  label="External Reference"
                  value="0"
                  checked={check === "0"}
                  //   onChange={handleCheckChange}
                />
              </div>

              <div id="app-primary-radio">
                <Form.Check
                  type="radio"
                  id="1"
                  label="Others"
                  value="1"
                  checked={check === "1"}
                  //   onChange={handleCheckChange}
                />
              </div>
            </Form.Group>
          </Row> */}
            <Row>
              <Form.Group
                as={Col}
                className="mb-4"
                controlId="validationCustom01">
                <span className="d-flex">
                  <Form.Label className="font-weight-bold mb-2">
                    Title
                  </Form.Label>
                </span>
                <div className="app-primary-input-field">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter Learning Title"
                    disabled={isCreatedBy === "true"}
                    value={title}
                    className={titleCheck ? "form-control is-invalid" : ""}
                    name="finance_title"
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                </div>
                {titleCheck ? (
                  <p className="m-0 text-danger" style={{ fontSize: "14.5px" }}>
                    Required*
                  </p>
                ) : null}
                <Form.Control.Feedback type="invalid">
                  Required*
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                as={Col}
                className="mb-4"
                controlId="validationCustom01">
                <span className="d-flex">
                  <Form.Label className="font-weight-bold mb-2">
                    Reference URL
                  </Form.Label>
                </span>
                <div className="app-primary-input-field">
                  <Form.Control
                    required
                    disabled={isCreatedBy === "true"}
                    className={urlCheck ? "form-control is-invalid" : ""}
                    type="text"
                    placeholder="Enter Reference URL"
                    value={url}
                    name="finance_title"
                    onChange={(e) => {
                      setUrl(e.target.value);
                    }}
                  />
                </div>
                {urlCheck ? (
                  <p className="m-0 text-danger" style={{ fontSize: "14.5px" }}>
                    Required*
                  </p>
                ) : null}
                <Form.Control.Feedback type="invalid">
                  Required*
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Description</Form.Label>
                <div className="app-primary-input-field">
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Enter Learning Description"
                    value={description}
                    disabled={isCreatedBy === "true"}
                    className={
                      descriptionCheck
                        ? "form-text-field form-control is-invalid"
                        : ""
                    }
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  />
                </div>
                {descriptionCheck ? (
                  <p className="m-0 text-danger" style={{ fontSize: "14.5px" }}>
                    Required*
                  </p>
                ) : null}
                <Form.Control.Feedback type="invalid">
                  Required*
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label className="d-flex justify-content-between">
                  <p className="my-auto">Owners</p>
                  <Button
                    className="app-icon-primary-btn"
                    disabled={isCreatedBy === "true"}
                    onClick={() => {
                      setShowModal(true);
                    }}>
                    +
                  </Button>
                </Form.Label>
                <div className="app-primary-input-field">
                  <Form.Control
                    required
                    disabled
                    type="input"
                    placeholder="Select Learning Owners..."
                    className="text-field"
                    value={
                      owners?.length === 0
                        ? ""
                        : owners?.length > 0 && owners?.length <= 3
                        ? `${owners && owners?.map((item) => item.name)}`
                        : owners?.length > 3
                        ? `${
                            owners &&
                            owners?.slice(0, 3)?.map((item) => item.name)
                          } + ${owners?.length - 3} more...`
                        : null
                    }
                  />
                </div>

                <Form.Label className="font-weight-bold mb-2 mt-4">
                  Platform Connections
                </Form.Label>
                <div className="app-primary-dropdown">
                  <MultipleSelectDropdown
                    multi={true}
                    placeholder="Select Related Platforms..."
                    loading={loading}
                    options={tools && tools}
                    values={selectedTools}
                    className="dropdown-select"
                    onChange={handleToolChange}
                  />
                </div>

                <Form.Control.Feedback type="invalid">
                  Required*
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Upload Image</Form.Label>

                <ImageUpload
                  className="main-container"
                  editImage={courseImage ? courseImage : null}
                  disabled={isCreatedBy === "true"}
                  setIsDeleted={setIsDeleted}
                  isAppImage={true}
                  onDrop={(files) => {
                    setCourseImage(files[0]);
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label className="font-weight-bold mb-2">
                  Application Connections
                </Form.Label>
                <div className="app-primary-dropdown">
                  <MultipleSelectDropdown
                    multi={true}
                    loading={loading}
                    placeholder="Select Related Applications..."
                    options={apps}
                    values={selectedApps}
                    className="dropdown-select"
                    onChange={handleAppChange}
                  />
                </div>

                <Form.Control.Feedback type="invalid">
                  Required*
                </Form.Control.Feedback>

                <Form.Label className="font-weight-bold mb-2 mt-4">
                  Process Connections
                </Form.Label>
                <div className="app-primary-dropdown">
                  <MultipleSelectDropdown
                    loading={loading}
                    multi={true}
                    placeholder="Select Related Process..."
                    options={process}
                    values={selectedProcess}
                    className="dropdown-select"
                    onChange={handleProcessChange}
                  />
                </div>

                <Form.Control.Feedback type="invalid">
                  Required*
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group
                as={Col}
                className="mb-4"
                controlId="validationCustom01">
                <span className="d-flex">
                  <Form.Label className="font-weight-bold mb-2">
                    Skills
                  </Form.Label>
                </span>
                <div className="app-primary-dropdown disabled-bg-color">
                  <MultipleSelectDropdown
                    multi={true}
                    placeholder="Select Related Skills..."
                    values={skills}
                    loading={loading}
                    className="dropdown-select"
                    disabled={true}
                  />
                </div>

                <Form.Control.Feedback type="invalid">
                  Required*
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                as={Col}
                className="mb-4"
                controlId="validationCustom01">
                <span className="d-flex">
                  <Form.Label className="font-weight-bold mb-2">
                    Curricula
                  </Form.Label>
                </span>
                <div className="app-primary-dropdown disabled-bg-color">
                  <MultipleSelectDropdown
                    multi={true}
                    placeholder="Select Related Curricula..."
                    values={curriculaOptions}
                    loading={loading}
                    className="dropdown-select"
                    disabled={true}
                  />
                </div>

                <Form.Control.Feedback type="invalid">
                  Required*
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            {showModal === true ? (
              <EmployeeModal
                show={showModal}
                handleClose={handleCloseModal}
                type={"owner"}
                getMembers={owners}
                title={"Add Owners To Learning"}
                setMember={setOwners}
                buttonTitle={"Add Owners"}
                role={"is-head"}
                is_owners={true}
              />
            ) : (
              ""
            )}
          </Form>
        )}

        <div className="d-flex justify-content-end font-weight-bold">
          <Button
            className="app-secondary-btn ms-3 my-auto"
            onClick={() => navigate(-1)}>
            {" "}
            Cancel
          </Button>

          <Button className="app-primary-btn ms-3" onClick={handleSave}>
            {" "}
            {loading === true ? "Saving..." : "Save"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddEditFinergyLearning;
