import React, { useState, useEffect } from "react";
import ResponsivePagination from "react-responsive-pagination";
import { Container, Row, Col, Nav, Button, ButtonGroup } from "react-bootstrap";
import GenericCard from "../../Cards/GenericCard";
import { getBaseUrl } from "../../../config/environment";
import GenericFilter from "../GenericFilter/GenericFilter";
import DetailView from "./DetailView";
import { EmployeeModal } from "../EmployeeModal";
import Loader from "../../Loader/Loader";
import ImageSvgs from "../../ImageSvgs";
// import { MultipleSelectDropdown } from "../MultipleSelect";
import TanstackReactFinTable from "../TanstackReactFCTable/TanstackReactFinTable";
import "./ResponsiveGrid.scss";
import { useNavigate } from "react-router-dom";

const ResponsiveGrid = (props) => {
  // eslint-disable-next-line no-unused-vars
  const parentId = props?.parentsId;
  const navigate = useNavigate();
  const [showCardDetails, setShowCardDetails] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dropdownShow, setDropdownShow] = useState(false);
  const adminCheck = sessionStorage.getItem("adminCheck");

  useEffect(() => {
    const { pathname, search } = window.location;
    const urlParams = new URLSearchParams(search);
    const viewIdParam = urlParams.get("viewId");

    if (viewIdParam && pathname?.includes("learning")) {
      setShowCardDetails(true);
    } else {
      setShowCardDetails(false);
    }
  }, []);

  const handleCardClick = (id) => {
    props.showCardDetails(id);
    setShowCardDetails(true);
  };

  const handleRowClick = (row) => {
    const rowData = row.original;
    props.showCardDetails(rowData.id);
    setShowCardDetails(true);
  };

  const handleApply = (value) => {
    props.handleApply();
    setDropdownShow(false);
  };

  const handleCancelApply = () => {
    setDropdownShow(false);
    props.handleCancel();
  };

  const handleHideDetail = () => {
    setShowCardDetails(false);
    if (window.location.pathname.includes("learning")) {
      setShowCardDetails(false);
      navigate(`/landscape/finergy/learning/${props.parentId}`);
    } else {
      setShowCardDetails(false);
    }
  };

  const handleAdd = () => {
    props.handleAdd();
  };

  return (
    <div>
      <div className="main-layout-container">
        <Container fluid className="mx-2 me-3 options-container">
          <Row>
            <Col
              xs={6}
              xl={6}
              className="d-flex justify-content-start w-50 p-0"
            >
              <ButtonGroup className="my-2 d-flex flex-wrap flex-row justify-content-center">
                {props.firstViewTitle}
                {props.secondViewTitle}
              </ButtonGroup>
            </Col>
            <Col xs={6} xl={6} className="d-flex justify-content-end w-50 p-0">
              <div className="d-flex pt-1">
                <Nav.Link className="my-auto">
                  <Button
                    className="app-tertiary-btn"
                    onClick={() => handleAdd()}
                  >
                    {adminCheck === "true" && <>{props.buttonTitle}</>}
                  </Button>
                </Nav.Link>

                <div className="app-primary-input-field custom-search pt-1 ms-3">
                  <input
                    type="search"
                    id="message"
                    placeholder={props.inputPlaceholder}
                    onChange={props.handleInputChange}
                  />
                </div>

                <GenericFilter
                  show={dropdownShow}
                  onToggle={() => setDropdownShow(false)}
                  onSelect={() => setDropdownShow(!dropdownShow)}
                  filterOptions={props.filterOptions}
                  onApply={handleApply}
                  onCancel={handleCancelApply}
                  onClear={props.handleClear}
                  title={props.filterTitle}
                />
              </div>
            </Col>
          </Row>
        </Container>

        <Container fluid className="mt-4 ">
          <div className="response-values">
            {props.loading ? (
              <div className="custom-loader">
                <Loader />
              </div>
            ) : (
              <>
                {props.activeBtn === "cardView" ? (
                  <div
                    className={props.res?.length > 0 ? "grid-container" : ""}
                  >
                    <div
                      className={
                        props.res?.length > 0
                          ? "row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5"
                          : ""
                      }
                    >
                      {props.res?.length > 0 ? (
                        props.res?.map((data) => (
                          <div key={data.id} className="col">
                            <GenericCard
                              id={data.id?.toString()}
                              header={data.attributes["courses-title"]}
                              tags={props.tags(
                                data.attributes["class-delivery-name-data"]
                              )}
                              description={
                                data.attributes["courses-description"] !== "NaN"
                                  ? data.attributes["courses-description"]
                                  : "N/A"
                              }
                              overlayTriggers={{
                                header: data.attributes["courses-title"],
                                description:
                                  data.attributes["courses-description"],
                              }}
                              imageSrc={
                                data?.attributes?.["course-image"]
                                  ? `${getBaseUrl()}${
                                      data.attributes?.["course-image"]
                                    }`
                                  : `${getBaseUrl()}/images/default-images/default-news.jpg`
                              }
                              imageAlt={`${getBaseUrl()}/images/default-images/default-news.jpg`}
                              isHover={true}
                              onClick={() => handleCardClick(data.id)}
                              footer={props.cardFooter(
                                data.attributes["courses-redirect-url"],
                                data.attributes["courses-average-rating"],
                                data.attributes.owners.data
                              )}
                            />
                          </div>
                        ))
                      ) : (
                        <div className="d-flex justify-content-center no-data-check">
                          <ImageSvgs name="no_data" />
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="other-view ">
                    <TanstackReactFinTable
                      data={props?.res}
                      columns={props?.columns}
                      onRowClick={handleRowClick}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </Container>

        <footer className="footer">
          {props.res?.length > 0 && (
            <div className="d-flex justify-content-end pagination">
              <ResponsivePagination
                current={props.currentPage}
                total={props.totalPages}
                onPageChange={props.onPageChange}
              />

              {/* <MultipleSelectDropdown
              color="#8A00E5"
              className="dropdown-select mb-2"
              placeholder="Items per page"
              multi={false}
              disabled={false}
              onChange={props.handleItemPerPage}
              values={props.itemPerPage}
              options={perPageOptions?.map((value, index) => ({
                name: value.name,
                id: value.id,
              }))}
            /> */}
            </div>
          )}
        </footer>
      </div>
      {showCardDetails && (
        <DetailView
          show={showCardDetails}
          hide={handleHideDetail}
          parentId={props.parentId}
          setUpdateData={props.setUpdataData}
          updateDataStatus={props.updateData}
          setShowCardDetails={setShowCardDetails}
        />
      )}

      {showModal && (
        <EmployeeModal
          show={showModal}
          type={"owners"}
          handleClose={() => setShowModal(false)}
          // getMembers={processDeputy}
          // getExisting={processOwner}
          title={"Add Deputy To Process"}
          // setMember={setProcessDeputy}
          buttonTitle={"Add Deputies"}
        />
      )}
    </div>
  );
};

export default ResponsiveGrid;
