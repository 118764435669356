import { Modal, Button } from "react-bootstrap";

export default function LearningDeleteModal(props) {
  const handleDelete = async () => {
    props.deleteAction();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="delete-modal my-auto"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="pt-1 text-capitalize">
          <strong>
            {props.modalContentWithTitle}: {props.title}{" "}
          </strong>
          <br></br>
        </span>
        <span className="pt-1">
          <span className="d-flex flex-column">
            <span>{props.confimationMessage}</span>
          </span>
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button
          id="common-favbutton"
          onClick={() => {
            handleDelete();
          }}
        >
          {props.updateStatus ? "Deleting..." : "Delete"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
