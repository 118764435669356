import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./TagsContainer.scss";

const TagsContainer = (props) => {
  const navigate = useNavigate();

  const handleTagClick = (e, link) => {
    e.stopPropagation();
    if (link) {
      navigate(link);
    }
  };

  return (
    <div className="tags-modal-container">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.showModal}
        onHide={props.handleModalClose}
        className="tags-modal"
        data-testid="tags-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-inline-items">
            {props.data
              ?.sort((a, b) => a.title.localeCompare(b.title))
              .map((item) => (
                <div
                  className={`custom-item ${props.className}`}
                  key={item.id}
                  onClick={(e) => handleTagClick(e, item.link)}
                >
                  {item.title}
                </div>
              ))}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TagsContainer;
