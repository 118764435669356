import React from "react";
import { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { MultipleSelectDropdown } from "../../components/CommonComponents/MultipleSelect";
import ImageSvgs from "../ImageSvgs";
import { useNavigate, useParams } from "react-router-dom";
import { getHostUrl } from "../../config/environment";
import { getData, patchData, postData } from "../../services/apiService";
import { Nav } from "react-bootstrap";
import Loader from "../Loader/Loader";

const AddEditFinanceField = ({ setLevel }) => {
  const navigate = useNavigate();
  const params = useParams();
  const fieldId = params ? params?.fieldId : null;
  const [title, setTitle] = useState("");
  const [titleCheck, setTitleCheck] = useState(false);

  const [financeOptions, setFinanceOptions] = useState([]);
  const [finValues, setFinValues] = useState([]);

  const [personalOptions, setPersonalOptions] = useState([]);
  const [personalValues, setPersonalValues] = useState([]);

  const [digitalOptions, setDigitalOptions] = useState([]);
  const [digiValues, setDigiValues] = useState([]);

  const [curriculaOptions, setCurriculaOptions] = useState([]);
  const [curriculaValues, setCurriculaValues] = useState([]);

  const [check, setCheck] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      let dataRes;
      if (fieldId) {
        dataRes = await getData(
          `${getHostUrl()}financefields/${fieldId && fieldId}`
        );
        await setTitle(
          dataRes?.data?.data?.attributes?.["finance-fields-title"]
        );
      }

      let digiRes = await getData(
        `${getHostUrl()}skills?skill_group=digital&use_pagination=false`
      );
      let finRes = await getData(
        `${getHostUrl()}skills?skill_group=finance&use_pagination=false`
      );
      let personalRes = await getData(
        `${getHostUrl()}skills?skill_group=personal&use_pagination=false`
      );
      let curriculaRes = await getData(
        `${getHostUrl()}curricula?use_pagination=false`
      );

      const digiOptions = digiRes?.data?.data?.map((item) => {
        return {
          type: "skills_finance_mapping",
          name: `${item.attributes["skill-title"]}`,
          attributes: {
            "skills-id": item.id.toString(),
          },
          id: null,
        };
      });
      setDigitalOptions(digiOptions);
      setDigiValues(
        dataRes?.data?.data?.attributes?.skills?.data?.filter(
          (item) => item?.attributes?.digital && item?.attributes?.digital?.data
        ).length > 0
          ? dataRes?.data?.data?.attributes?.skills?.data
              ?.filter(
                (item) =>
                  item?.attributes?.digital && item?.attributes?.digital?.data
              )
              ?.map((elem) => {
                return {
                  type: "skills_finance_mapping",
                  name: elem?.attributes?.digital?.data?.attributes?.[
                    "skill-title"
                  ],
                  attributes: {
                    "skills-id": elem?.attributes?.["skills-id"],
                  },
                  id: elem?.id,
                };
              })
          : []
      );

      const finOptions = finRes?.data?.data?.map((item) => {
        return {
          type: "skills_finance_mapping",
          name: `${item.attributes["skill-title"]}`,
          attributes: {
            "skills-id": item.id.toString(),
          },
          id: null,
        };
      });
      setFinanceOptions(finOptions);
      setFinValues(
        dataRes?.data?.data?.attributes?.skills?.data?.filter(
          (item) => item?.attributes?.finance && item?.attributes?.finance?.data
        ).length > 0
          ? dataRes?.data?.data?.attributes?.skills?.data
              ?.filter(
                (item) =>
                  item?.attributes?.finance && item?.attributes?.finance?.data
              )
              ?.map((elem) => {
                return {
                  type: "skills_finance_mapping",
                  name: elem?.attributes?.finance?.data?.attributes?.[
                    "skill-title"
                  ],
                  attributes: {
                    "skills-id": elem?.attributes?.["skills-id"],
                  },
                  id: elem?.id,
                };
              })
          : []
      );

      const perOptions = personalRes?.data?.data?.map((item) => {
        return {
          type: "skills_finance_mapping",
          name: `${item.attributes["skill-title"]}`,
          attributes: {
            "skills-id": item.id.toString(),
          },
          id: null,
        };
      });
      setPersonalOptions(perOptions);
      setPersonalValues(
        dataRes?.data?.data?.attributes?.skills?.data?.filter(
          (item) =>
            item?.attributes?.personal && item?.attributes?.personal?.data
        ).length > 0
          ? dataRes?.data?.data?.attributes?.skills?.data
              ?.filter(
                (item) =>
                  item?.attributes?.personal && item?.attributes?.personal?.data
              )
              ?.map((elem) => {
                return {
                  type: "skills_finance_mapping",
                  name: elem?.attributes?.personal?.data?.attributes?.[
                    "skill-title"
                  ],
                  attributes: {
                    "skills-id": elem?.attributes?.["skills-id"],
                  },
                  id: elem?.id,
                };
              })
          : []
      );

      const currOptions = curriculaRes?.data?.data?.map((item) => {
        return {
          type: "curricula",
          name: `${item.attributes["curricula-title"]}`,
          attributes: {
            "curricula-id": item.id.toString(),
          },
          id: null,
        };
      });
      setCurriculaOptions(currOptions);
      setCurriculaValues(
        dataRes?.data?.data?.attributes?.curricula?.data?.length > 0
          ? dataRes?.data?.data?.attributes?.curricula?.data?.map((elem) => {
              let temp = curriculaRes?.data?.data?.filter(
                (item) =>
                  item.type === "curricula" &&
                  item?.id?.toString() ===
                    elem?.attributes?.["curricula-id"].toString()
              )[0];
              return {
                type: "curricula",
                name: `${temp?.attributes?.["curricula-title"]}`,
                attributes: {
                  "curricula-id": temp?.id.toString(),
                },
                id: elem?.id.toString(),
              };
            })
          : []
      );

      setLoading(false);
    })();
    // eslint-disable-next-line
  }, []);

  const handleDigiChange = async (value) => {
    let finalValue = [];
    value?.map((item) => {
      let newElem = {
        type: "skills_finance_mapping",
        name: item.name,
        attributes: {
          "skills-id": item.attributes["skills-id"].toString(),
        },
        id: item.id ? item.id.toString() : null,
      };
      finalValue.push(newElem);
      return null;
    });

    setDigiValues(finalValue);
  };

  const handleFinChange = async (value) => {
    let finalValue = [];
    value?.map((item) => {
      let newElem = {
        type: "skills_finance_mapping",
        name: item.name,
        attributes: {
          "skills-id": item.attributes["skills-id"].toString(),
        },
        id: item.id ? item.id.toString() : null,
      };
      finalValue.push(newElem);
      return null;
    });

    setFinValues(finalValue);
  };

  const handlePersonalChange = async (value) => {
    let finalValue = [];
    value?.map((item) => {
      let newElem = {
        type: "skills_finance_mapping",
        name: item.name,
        attributes: {
          "skills-id": item.attributes["skills-id"].toString(),
        },
        id: item.id ? item.id.toString() : null,
      };
      finalValue.push(newElem);
      return null;
    });

    setPersonalValues(finalValue);
  };

  const handleCurriculaChange = async (value) => {
    let finalValue = [];
    value?.map((item) => {
      let newElem = {
        type: "curricula",
        name: item.name,
        attributes: {
          "curricula-id": item.attributes["curricula-id"].toString(),
        },
        id: item.id ? item.id.toString() : null,
      };
      finalValue.push(newElem);
      return null;
    });

    setCurriculaValues(finalValue);
  };

  const buildPayload = () => {
    return {
      data: {
        type: "finance_fields",
        attributes: {
          finance_fields_title: title && title,
          finance_fields_description: "",
          curricula: {
            data: curriculaValues && curriculaValues,
          },
          personal: {
            data: personalValues && personalValues,
          },
          digital: {
            data: digiValues && digiValues,
          },
          finance: {
            data: finValues && finValues,
          },
        },
      },
    };
  };

  const buildUrl = () => {
    if (fieldId) {
      return `${getHostUrl()}financefields/${fieldId}`;
    } else {
      return `${getHostUrl()}financefields`;
    }
  };

  const handleEditField = async () => {
    setCheck(true);
    let payload = buildPayload();
    let url = buildUrl();
    try {
      const res = await patchData(url, payload);
      if (res.status === 200) {
        setCheck(false);
        navigate(-1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddField = async () => {
    setCheck(true);
    const payload = buildPayload();
    const url = buildUrl();
    try {
      const res = await postData(url, payload);
      if (res.status === 200) {
        setCheck(false);
        navigate(-1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {loading ? (
        <div className="custom-loader">
          <Loader />
        </div>
      ) : (
        <div className="p-5">
          <div className="back-icon d-flex">
            <Nav.Link onClick={() => navigate(-1)} data-testid="back-button">
              {" "}
              <ImageSvgs name="goBack" />
            </Nav.Link>
            <span className="ps-1 fw-bold"> Back </span>
          </div>
          <h4 className="fw-bold mt-2">
            {fieldId ? "Edit Finance Field" : "Add New Finance Field"}
          </h4>
          <Form noValidate className="tool-form">
            <Row>
              <Form.Group
                as={Col}
                className="mb-4"
                controlId="validationCustom01">
                <span className="d-flex">
                  <Form.Label className="font-weight-bold mb-2">
                    Title
                  </Form.Label>
                </span>
                <div className="app-primary-input-field">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter Finance Field Title"
                    value={title}
                    name="finance_title"
                    className={titleCheck ? "form-control is-invalid" : ""}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                </div>
                {titleCheck ? (
                  <p className="m-0 text-danger" style={{ fontSize: "14.5px" }}>
                    Required*
                  </p>
                ) : null}
                <Form.Control.Feedback type="invalid">
                  Required*
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                as={Col}
                className="mb-4"
                controlId="validationCustom01">
                <span className="d-flex">
                  <Form.Label className="font-weight-bold mb-2">
                    Related Finance Skills
                  </Form.Label>
                </span>
                <div className="app-primary-dropdown">
                  <MultipleSelectDropdown
                    multi={true}
                    placeholder="Select Related Finance Skills..."
                    options={financeOptions && financeOptions}
                    onChange={handleFinChange}
                    values={finValues}
                    className="dropdown-select"
                  />
                </div>

                <Form.Control.Feedback type="invalid">
                  Required*
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group
                as={Col}
                className="mb-4"
                controlId="validationCustom01">
                <span className="d-flex">
                  <Form.Label className="font-weight-bold mb-2">
                    Related Personal Skills
                  </Form.Label>
                </span>
                <div className="app-primary-dropdown">
                  <MultipleSelectDropdown
                    multi={true}
                    placeholder="Select Related Personal Skills..."
                    options={personalOptions && personalOptions}
                    onChange={handlePersonalChange}
                    values={personalValues}
                    className="dropdown-select"
                  />
                </div>

                <Form.Control.Feedback type="invalid">
                  Required*
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                as={Col}
                className="mb-4"
                controlId="validationCustom01">
                <span className="d-flex">
                  <Form.Label className="font-weight-bold mb-2">
                    Related Digital Skills
                  </Form.Label>
                </span>
                <div className="app-primary-dropdown">
                  <MultipleSelectDropdown
                    multi={true}
                    placeholder="Select Related Digital Skills..."
                    options={digitalOptions && digitalOptions}
                    onChange={handleDigiChange}
                    values={digiValues}
                    className="dropdown-select"
                  />
                </div>

                <Form.Control.Feedback type="invalid">
                  Required*
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3" style={{ width: "50.8%" }}>
              <Form.Group
                as={Col}
                className="mb-4"
                controlId="validationCustom01">
                <span className="d-flex">
                  <Form.Label className="font-weight-bold mb-2">
                    Related Curricula
                  </Form.Label>
                </span>
                <div className="app-primary-dropdown">
                  <MultipleSelectDropdown
                    multi={true}
                    placeholder="Select Related Curricula..."
                    options={curriculaOptions}
                    onChange={handleCurriculaChange}
                    values={curriculaValues}
                    className="dropdown-select"
                  />
                </div>

                <Form.Control.Feedback type="invalid">
                  Required*
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Form>
          <div className="d-flex justify-content-end font-weight-bold">
            <Button
              className="app-secondary-btn ms-3 my-auto"
              onClick={() => navigate(-1)}>
              {" "}
              Cancel
            </Button>

            <Button
              className="app-primary-btn ms-3"
              onClick={() => {
                if (title?.length > 0) {
                  setTitleCheck(false);
                  if (fieldId) {
                    handleEditField();
                  } else {
                    handleAddField();
                  }
                } else {
                  setTitleCheck(true);
                }
              }}>
              {check === true ? "Saving..." : "Save"}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default AddEditFinanceField;
