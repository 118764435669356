import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import LearningDetailPage from "../../FinergyComponents/LearningDetailPage";

export default function DetailView(props) {
  //% cases  should be handled for the details page
  return (
    <div>
      <Offcanvas
        show={props.show}
        onHide={props.hide}
        parentId={props?.parentId}
        setUpdateData={props?.setUpdateData}
        updateStatus={props.updateDataStatus}
        showCardDetails={props.setShowCardDetails}
        placement="end"
        sm={12}
        lg={12}
        className="card-details"
      >
        <Offcanvas.Body>
          <LearningDetailPage
            onHide={props.hide}
            parentId={props?.parentId}
            setUpdateData={props.setUpdateData}
            updateStatus={props.updateDataStatus}
            showCardDetails={props.setShowCardDetails}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}
