import { Modal } from "react-bootstrap";
import { getBaseUrl } from "../../config/environment";
import ImageSvgs from "../ImageSvgs";

export default function MyVerticallyCenteredModal(props) {
  const data = props.data;

  const title =
    data.type === "training"
      ? data.attributes["training-name"]
      : data.type === "application"
      ? data.attributes["app-title"]
      : data.type === "process"
      ? data.attributes.title
      : data.type === "community"
      ? data.attributes["community-name"]
      : data.type === "topics"
      ? data.type["topics-name"]
      : data.type === "tool"
      ? data.attributes["tool-name"]
      : data.type === "learning"
      ? data.attributes["courseTitle"]
      : "Title";

  const desc =
    data.type === "training"
      ? data.attributes["training-description"]
      : data.type === "application"
      ? data.attributes["app-description"]
      : data.type === "process"
      ? data.attributes.desc
      : data.type === "community"
      ? data.attributes["community-desc"]
      : data.type === "topics"
      ? data.attributes["topics-description"]
      : data.type === "tool"
      ? data.attributes["tool-description"]
      : data.type === "learning"
      ? data.attributes["courseDesc"]
      : "Description";

  const image =
    data.type === "training"
      ? data.attributes["training-image"]
      : data.type === "application"
      ? data.attributes["app-image"]
      : data.type === "community"
      ? data.attributes["community-image"]
      : data.type === "tool"
      ? data.attributes["tool-image"]
      : "";

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="d-flex">
          {image?.length > 0 ? (
            <img
              className="app-image"
              src={`${getBaseUrl()}${image}`}
              alt="app"
            />
          ) : (
            <ImageSvgs name="app_icon" />
          )}
          <p className="ms-2 mb-0">{title}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <b className="me-1">Description:</b>
          <div style={{ whiteSpace: "pre-line" }}>{desc}</div>
          {/* {desc} */}
        </p>
      </Modal.Body>
    </Modal>
  );
}
