import React from "react";
import Spinner from "react-bootstrap/Spinner";

export default function Loader() {
  return (
    <div className="loader-container w-100">
      <div>
        <Spinner animation="border" style={{ color: "#8a00e5" }} role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    </div>
  );
}
