import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TagsContainer from "./TagsContainer";
import "./GenericTags.scss";

const GenericTags = (props) => {
  const { data, className } = props;
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleModalOpen = (e) => {
    e.stopPropagation();
    setShowModal(true);
  };

  const handleModalClose = (e) => {
    if (e) {
      e.stopPropagation();
    }
    setShowModal(false);
  };

  const renderDropdown = () => {
    return (
      <div className="tag-dropdown-menu p-2">
        {data
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((item, index) => (
            <div
              key={index}
              className={`tag-dropdown-item mt-2 mb-2 ${className}`}
              onClick={(e) => {
                e.stopPropagation();
                if (item.link) {
                  e.preventDefault();
                  navigate(item.link);
                }
              }}
              data-testid={`tag-item-${item.title}`}>
              <div className="d-flex flex-column">{item.title}</div>
            </div>
          ))}
      </div>
    );
  };

  const renderTags = () => {
    if (data?.length > 1 && data?.length <= 5) {
      return (
        <div className="d-flex">
          <div
            className={className}
            onClick={(e) => {
              e.stopPropagation();
              if (data[0].link) {
                navigate(data[0].link);
              }
            }}>
            {data[0].title}
          </div>
          <div className="tag-dropdown">
            <button
              className="more-user-btn pt-3"
              onClick={(e) => e.stopPropagation()}>
              +{data.length - 1} more
            </button>
            {renderDropdown()}
          </div>
        </div>
      );
    }

    if (data.length > 5) {
      return (
        <div className="d-flex">
          <div
            className={className}
            onClick={(e) => {
              e.stopPropagation();
              if (data[0].link) {
                navigate(data[0].link);
              }
            }}
            data-testid={`tag-item-${data[0].title}`}>
            {data[0].title}
          </div>
          <button
            className="more-user-btn"
            onClick={(e) => {
              e.stopPropagation();
              handleModalOpen(e);
            }}>
            +{data.length - 1} more
          </button>
        </div>
      );
    }

    return (
      <div
        className={className}
        onClick={(e) => {
          e.stopPropagation();
          if (data?.length > 0 && data[0].link) {
            navigate(data[0].link);
          }
        }}>
        {data?.length > 0 ? data[0].title : null}
      </div>
    );
  };

  return (
    <div className="generic-tags" onClick={(e) => e.stopPropagation()}>
      {" "}
      {renderTags()}
      {data.length > 5 && showModal === true ? (
        <TagsContainer
          showModal={showModal}
          handleModalClose={handleModalClose}
          modalTitle={props.modalTitle}
          data={data}
          className={props.className}
          data-testid="modal-element"
        />
      ) : null}
    </div>
  );
};

export default GenericTags;
