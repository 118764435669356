import React, { useEffect, useState } from "react";
import OverviewPlaceholder from "./OverviewPlaceholder";
import MainComp from "../TrainingsDashboard/MainComp";
import "./FinergyOverviewPage.scss";
import { getBaseUrl } from "../../config/environment";

const URL_TO_FETCH_FINERGY_INTRODUCTION_JSON = `${getBaseUrl()}/configurations/FiNergy/OverviewIntroduction.json`;
const URL_TO_FETCH_FINERGY_INTRODUCTION_VIDEO = `${getBaseUrl()}/configurations/FiNergy/FINergy_Introduction.mp4`;

const FinergyOverviewPage = () => {
  const [finergyIntroduction, setFinergyIntroduction] = useState("");

  useEffect(() => {
    const fetchIntroduction = async () => {
      try {
        const response = await fetch(URL_TO_FETCH_FINERGY_INTRODUCTION_JSON);
        const data = await response.json();
        data ? setFinergyIntroduction(data) : setFinergyIntroduction("");
      } catch (error) {
        setFinergyIntroduction("Error fetching the introduction.");
      }
    };

    fetchIntroduction();
  }, []);

  return (
    <div className="overview">
      <div className="row ms-1 me-1 overview-container my-4">
        <div className="col-md-12 overview-comp data ps-3 pe-3 py-1">
          <div className="d-flex title">
            <p className="fw-bold fs-4 mb-2">About FINergy Academy </p>
          </div>
          <div className="row flex-wrap align-items-start">
            <div className="col-6">
              <video controls data-testid="video-element" className="video">
                <source
                  src={URL_TO_FETCH_FINERGY_INTRODUCTION_VIDEO}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div
              className="col-6 scrollable-content"
              data-testid="content-container">
              {finergyIntroduction.data?.split("\n").map((line, index) => (
                <p key={index}>{line}</p>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6  training-comp">
          <div className="overview-comp">
            <MainComp />
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="overview-comp">
            <OverviewPlaceholder title="Upcoming Finance Learning Hours" />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="overview-comp">
            <OverviewPlaceholder title="Trending Learnings" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="overview-comp">
            <OverviewPlaceholder title="Your Badge Gallery" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinergyOverviewPage;
