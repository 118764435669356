import React, { useEffect, useMemo, useState } from "react";
import ImageSvgs from "../ImageSvgs";
import ResponsiveGrid from "../CommonComponents/ResponsivePageLayout/ResponsiveGrid";
import Button from "react-bootstrap/Button";
import FinNestedList from "./FinNestedList";
import { getData } from "../../services/apiService";
import { getHostUrl } from "../../config/environment";
import { MdOpenInNew } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import OwnerProfile from "../CommonComponents/OwnerProfile";
import GenericTags from "../CommonComponents/GenericTags/GenericTags";
import Rating from "@mui/material/Rating";
import { Stack } from "@mui/material";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { UserModal } from "../../utils/toolsUtils";
import { debounce } from "lodash";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Loader from "../Loader/Loader";
import "./FinergyLearningPage.scss";

const FinergyLearningPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const parentId = params ? params?.parentId : null;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [itemPerPage, setItemPerPage] = useState("10");
  const [activeBtn, setActiveBtn] = useState("cardView");
  const [parentsId, setParentsId] = useState(parentId);
  const [res, setRes] = useState(() => []);
  const [loading, setLoading] = useState(false);
  const [nameQuery, setNameQuery] = useState("");
  const [deliveryType, setDeliveryType] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [language, setLanguage] = useState([]);
  const [showUserModal, setShowUserModal] = useState(false);
  const [ownerData, setOwnerData] = useState(false);
  const [hierarchy, setHierarchy] = useState();
  const [languageOptions, setLanguageOptions] = useState([]);
  const [deliveryTypeOptions, setDeliveryTypeOptions] = useState([]);
  const [updateData, setUpdataData] = useState(false);
  const location = useLocation();
  const [finalQueries, setFinalQueries] = useState({
    deliveryType: [],
    ratings: [],
    language: [],
  });

  const decodeFilterOptions = (searchParams) => {
    const decodedParams = {};
    for (const [key, value] of searchParams.entries()) {
      try {
        decodedParams[key] = JSON.parse(decodeURIComponent(value));
      } catch (error) {
        decodedParams[key] = decodeURIComponent(value);
      }
    }

    return decodedParams;
  };

  useEffect(() => {
    const searchFilterValues = new URLSearchParams(location.search);
    const decodedFilterValues = decodeFilterOptions(searchFilterValues);

    const deliveryTypeIds =
      decodedFilterValues.deliveryType?.length > 0
        ? decodedFilterValues.deliveryType.map((item) => item.id).join(", ")
        : [];
    const languageIds =
      decodedFilterValues.language?.length > 0
        ? decodedFilterValues.language.map((item) => item.id).join(", ")
        : [];

    setFinalQueries({
      deliveryType: deliveryTypeIds,
      ratings:
        decodedFilterValues.rating?.length > 0
          ? decodedFilterValues.rating
          : [],
      language: languageIds,
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function fetchData() {
      const language = `${getHostUrl()}courses/lang`;
      const deliveryType = `${getHostUrl()}courses/delivery`;
      const deliveryRes = await getData(deliveryType);
      const languageRes = await getData(language);

      if (deliveryRes.status === 200 && languageRes.status === 200) {
        setLanguageOptions(languageRes.data.data);
        setDeliveryTypeOptions(deliveryRes.data.data);
      }
    }
    fetchData();
  }, []);

  const buildParams = () => {
    const params = [];

    const addParam = (key, value) => {
      if (value) {
        params.push(`${key}=${encodeURIComponent(value)}`);
      }
    };

    if (
      hierarchy?.filter(
        (elem) => elem?.id?.toString() === parentId?.toString()
      )[0]?.attributes?.["type-data"]?.data?.attributes?.type !== "All"
    ) {
      addParam("skills_id", parentsId && parentsId);
    }
    addParam("search", nameQuery);
    addParam("delivery_name", finalQueries.deliveryType);
    addParam("average_rating", finalQueries.ratings[0]?.id);
    addParam("lang", finalQueries.language);
    return params;
  };

  const buildUrl = () => {
    const baseUrl = `${getHostUrl()}courses?template=id,courses_title,courses_description,courses_average_rating,class_delivery_name_data,courses_redirect_url,course_image,owners&use_pagination=true&page=${currentPage}&per_page=${parseInt(
      itemPerPage
    )}`;

    const params = buildParams();

    const validParams = params.filter(
      (param) => param.includes("=") && param.split("=")[1] !== ""
    );

    if (validParams.length > 0 || finalQueries.ratings) {
      return `${getHostUrl()}courses/search?${validParams.join("&")}&${
        baseUrl.split("?")[1]
      }`;
    }
  };

  const fetchCourseData = debounce(async () => {
    setLoading(true);
    const url = buildUrl();

    const res = await getData(url);

    if (res.status === 200) {
      let courses = res.data.data;
      setRes(courses);
      setTotalPages(res.data.meta["page-count"]);
    }
    setLoading(false);
  }, 500);

  useEffect(() => {
    (async () => {
      setLoading(true);
      let param = `${getHostUrl()}hierarchy`;
      const res = await getData(param);
      if (res.status === 200) {
        setHierarchy(res?.data?.data);
      }
      setLoading(false);
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchCourseData();
    return () => {
      if (hierarchy) {
        fetchCourseData.cancel();
      }
    };
    // eslint-disable-next-line
  }, [
    nameQuery,
    currentPage,
    itemPerPage,
    finalQueries,
    parentsId,
    hierarchy,
    updateData,
  ]);
  const ratingOptions = [1, 2, 3, 4, 5].map((rating) => ({
    id: rating,
    name: String.fromCharCode(11088).repeat(rating),
  }));

  const tags = (deliveryType) => {
    const deliveryTags =
      deliveryType?.data?.length > 0
        ? deliveryType?.data?.map((item) => ({
            id: item.id,
            title: item.attributes["delivery-name"],
            link: null,
            itemId: null,
          }))
        : [];

    if (deliveryTags.length === 0) {
      return <div className="empty-tags" />;
    }

    return (
      <div className="d-flex mt-1 mb-1">
        <GenericTags
          data={deliveryTags}
          className="learning-tags"
          modalTitle="Delivery Types"
        />
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "attributes.courses-title",
        id: "title",
        cell: (info) => {
          const value = info.getValue();
          return value?.length > 100 ? `${value?.substring(0, 100)}...` : value;
        },
        header: () => <span>Title</span>,
        footer: (props) => props.column.id,
        size: 700,
        className: "title-column",
        enableColumnFilter: false,
      },

      {
        accessorKey: "attributes.owners.data",
        id: "owners",
        cell: (info) => {
          const owners = info.getValue();
          if (!owners || owners.length === 0) {
            return "-";
          }
          return (
            <div>
              {owners.slice(0, 3).map((owner, index) => (
                <OwnerProfile key={index} owner={owner} isOwnerHasLink={true} />
              ))}
              {owners?.length > 3 ? (
                <button
                  className="more-user-btn"
                  onClick={async (e) => {
                    e.stopPropagation();
                    setOwnerData(owners);
                    setShowUserModal(true);
                  }}
                >
                  + {owners?.length - 3} more
                </button>
              ) : null}
            </div>
          );
        },
        header: () => <span>Owners</span>,
        footer: (props) => props.column.id,
        size: 400,
        enableColumnFilter: false,
      },
      {
        accessorKey: "attributes.courses-average-rating",
        id: "score",
        cell: (info) => {
          const value = info.getValue();
          return value === 0 || value === null ? (
            <>
              <FaStar className="rating_none" /> N/A
            </>
          ) : (
            <>
              <FaStar className="rating_full" /> {value}
            </>
          );
        },
        header: () => <span>Rating</span>,
        footer: (props) => props.column.id,
        size: 200,
        enableColumnFilter: false,
      },
      {
        accessorKey: "attributes.class-delivery-name-data.data",
        id: "tags",
        cell: (info) => {
          const value = info.getValue();
          const tagsData = {
            data: value,
          };
          return tags(tagsData);
        },
        header: () => <span>Delivery Type</span>,
        footer: (props) => props.column.id,
        size: 750,
        enableColumnFilter: false,
      },
      {
        id: "actions",
        cell: ({ row }) => (
          <MdOpenInNew
            className="redirect-btn"
            onClick={(e) => {
              e.stopPropagation();
              window.open(
                row.original.attributes["courses-redirect-url"],
                "_blank"
              );
            }}
          />
        ),
        header: () => <span>Actions</span>,
        footer: (props) => props.column.id,
      },
    ],
    []
  );

  const cardFooter = (redirectUrl, rating, owner) => {
    return (
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          {owner?.slice(0, 3)?.map((value, index) => (
            <>
              <OwnerProfile key={index} owner={value} isOwnerHasLink={true} />
            </>
          ))}
          {owner?.length > 3 ? (
            <button
              className="more-user-btn"
              onClick={async (e) => {
                e.stopPropagation();
                setOwnerData(owner);
                setShowUserModal(true);
              }}
            >
              + {owner?.length - 3} more
            </button>
          ) : null}
        </div>

        <div className="d-flex justify-content-end">
          <div>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-header`}>{`${
                  rating === null ? "0.0" : rating?.toFixed(1)
                } / 5.0`}</Tooltip>
              }
            >
              <Stack spacing={1} className="rating-stack">
                {rating === 0 || rating === null ? (
                  <Rating
                    name="size-large"
                    defaultValue={1}
                    size="large"
                    className="disabled-rating"
                  />
                ) : (
                  <Rating name="size-large" defaultValue={1} size="large" />
                )}
              </Stack>
            </OverlayTrigger>
          </div>

          <div className="redirect">
            <a
              href={redirectUrl}
              onClick={(e) => e.stopPropagation()}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ImageSvgs name="active_redirect_btn" />
            </a>
          </div>
        </div>
      </div>
    );
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNameQueryChange = (e) => {
    setNameQuery(e.target.value);
  };

  const handleRatingChange = (value) => {
    setRatings(value);
  };

  const handleLanguageChange = (value) => {
    setLanguage(value);
  };

  const handleDeliveryTypeChange = (value) => {
    setDeliveryType(value);
  };

  const handleButtonClick = (buttonId) => {
    setActiveBtn(buttonId);
  };

  const renderButton = (buttonId, label) => (
    <Button
      className={`form-select-btn d-flex flex-row justify-content-center w-auto py-1 px-2 ${
        activeBtn === buttonId ? "form-select-activeBtn" : ""
      }`}
      onClick={() => handleButtonClick(buttonId)}
    >
      <div className="d-flex flex-row align-items-center">
        {activeBtn === "cardView" ? (
          buttonId === "cardView" ? (
            <ImageSvgs name="cardView" />
          ) : (
            <ImageSvgs name="disableTableView" />
          )
        ) : buttonId === "cardView" ? (
          <ImageSvgs name="cardViewDisable" />
        ) : (
          <ImageSvgs name="tableView" />
        )}
        <div className="ms-2">{label}</div>{" "}
      </div>
    </Button>
  );

  const handlePerPage = (value) => {
    setItemPerPage(value[0].name);
  };

  const handleFilterApply = () => {
    setCurrentPage(1);
    const deliveryTypeIds = deliveryType.map((item) => item.id).join(", ");
    const languageIds = language.map((item) => item.id).join(", ");

    setFinalQueries({
      deliveryType: deliveryTypeIds,
      ratings: ratings,
      language: languageIds,
    });
  };

  const handleCancelFilter = () => {
    //# cancel event if any
  };

  const handleClearFilter = () => {
    setCurrentPage(1);
    setFinalQueries({
      deliveryType: [],
      ratings: [],
      language: [],
    });
  };

  const filterOptions = [
    {
      inputType: "multi-select",
      options: deliveryTypeOptions
        ?.map((value) => ({
          type: value.type,
          id: value.id,
          name: value.attributes["delivery-name"],
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
      queryKey: "deliveryType",
      placeholder: "Search by Delivery Type",
      multi: true,
      onChange: handleDeliveryTypeChange,
      selected: deliveryType,
      label: "Delivery Type",
    },
    {
      inputType: "multi-select",
      options: ratingOptions,
      queryKey: "rating",
      placeholder: "Search by Ratings",
      multi: false,
      onChange: handleRatingChange,
      selected: ratings,
      label: "Rating",
    },
    {
      inputType: "multi-select",
      options: languageOptions
        ?.map((lang) => ({
          type: lang.type,
          id: lang.id,
          name: lang.attributes["lang-name"],
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
      queryKey: "language",
      placeholder: "Search by Language",
      multi: true,
      onChange: handleLanguageChange,
      selected: language,
      label: "Language",
      disabled: false,
    },
  ];

  const showCardDetails = (id) => {
    const currentParams = window.location.search;
    let baseUrl = `/landscape/finergy/learning/${parentId}?viewId=${id}`;
    if (currentParams) {
      const urlParams = new URLSearchParams(currentParams);
      urlParams.forEach((value, key) => {
        urlParams.set(key, decodeURIComponent(value));
      });
      baseUrl += `&${urlParams.toString()}`;
    }

    navigate(baseUrl);
  };

  const handleAddLearning = () => {
    navigate(`/manage_learnings/add_learning`);
  };

  return (
    <>
      {hierarchy && hierarchy ? (
        <div className=" d-flex w-100">
          <div className="w-25 left-border nested-list-container">
            {
              <FinNestedList
                hierarchy={hierarchy && hierarchy}
                setParentsId={setParentsId}
                parentsId={parentsId}
              />
            }
          </div>
          <div className="w-75">
            <ResponsiveGrid
              buttonTitle={"Add Learning"}
              inputPlaceholder={"Search Courses ..."}
              filterTitle="Advanced Filters"
              cardFooter={cardFooter}
              tags={tags}
              firstViewTitle={renderButton("cardView", "Card")}
              secondViewTitle={renderButton("tableView", "Table")}
              res={res}
              currentPage={currentPage}
              parentId={parentId}
              totalPages={totalPages}
              activeBtn={activeBtn}
              columns={columns}
              loading={loading}
              itemPerPage={itemPerPage}
              filterOptions={filterOptions}
              onPageChange={handlePageChange}
              handleItemPerPage={handlePerPage}
              handleApply={handleFilterApply}
              handleCancel={handleCancelFilter}
              handleClear={handleClearFilter}
              handleInputChange={handleNameQueryChange}
              setUpdataData={setUpdataData}
              updateData={updateData}
              handleAdd={handleAddLearning}
              showCardDetails={showCardDetails}
            />
          </div>
          {showUserModal === true ? (
            <UserModal
              title={"Learning Owners:-"}
              listdata={ownerData}
              show={showUserModal}
              onHide={() => setShowUserModal(false)}
            />
          ) : null}
        </div>
      ) : (
        <div className="custom-loader">
          <Loader />
        </div>
      )}
    </>
  );
};

export default FinergyLearningPage;
