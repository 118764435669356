import React, { useState, useEffect } from "react";
import "./AdminScreen.scss";
import UserTable from "./UserTable";
import { Button } from "react-bootstrap";
import { getData } from "../../services/apiService";
import { getHostUrl } from "../../config/environment";
import { AddUserModal } from "../../utils/userManagementUtils";
import { useNavigate } from "react-router";
import ImageSvgs from "../ImageSvgs";
import AdminCards from "./AdminCards";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ResponsivePagination from "react-responsive-pagination";
import Loader from "../Loader/Loader";
import { debounce } from "lodash";

const MainScreen = () => {
  const navigate = useNavigate();
  const [addModalShow, setAddModalShow] = useState(false);
  const [userData, setUserData] = useState(null);
  const [included, setIncluded] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);
  const [getRes, setGetRes] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [query, setQuery] = useState("");
  const [cardData, setCardData] = useState(null);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPages] = useState();
  const [isLoad, setIsLoad] = useState(false);

  const param = `${getHostUrl()}user?include=employee_data&page=${currentPage}&per_page=${itemsPerPage}&use_pagination=true`;
  const searchUser = `${getHostUrl()}user/search?include=employee_data&search=${query}`;
  const data = `${getHostUrl()}user/count`;

  async function getResponse() {
    const adRes = await getData(
      `${getHostUrl()}user/check/${
        JSON.parse(sessionStorage.getItem("userData")).id
      }`
    );
    await setIsAdmin(adRes.data);
    if (adRes.data) {
      try {
        setShowLoader(true);
        const res = await getData(param);
        const cardRes = await getData(data);
        const searchRes = await getData(searchUser);

        if (res && cardRes) {
          if (query?.length !== 0) {
            setUserData(searchRes.data.data);
            setTotalPages(searchRes.data.meta["page-count"]);
            setItemPerPage(searchRes.data.meta["per-page"]);
            setIncluded(res.data.included);
            setCardData(cardRes.data.data[0]);
            setShowLoader(false);
          } else {
            setGetRes(true);
            setUserData(res.data.data);
            setTotalPages(res.data.meta["page-count"]);
            setItemPerPage(res.data.meta["per-page"]);
            setIncluded(res.data.included);
            setCardData(cardRes.data.data[0]);
            setShowLoader(false);
          }
        }
      } catch (err) {
        if (err) {
          setGetRes(false);
          if (err.response.status === 401) {
            setGetRes(true);
          }
        }
      }
    }
  }
  const debouncedGetResponse = debounce(getResponse, 300);

  useEffect(() => {
    debouncedGetResponse();
    return debouncedGetResponse.cancel;
    // eslint-disable-next-line
  }, [currentPage, query, isLoad]);

  useEffect(() => {
    setCurrentPage(currentPage);
  }, [currentPage]);

  if (userData) {
    userData.sort(function (x, y) {
      return (
        Number(y.attributes["is-admin"]) - Number(x.attributes["is-admin"])
      );
    });
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleUserSearch = (e) => {
    const queryValue = e.target.value;
    setQuery(queryValue.toLowerCase());
  };

  return (
    <div className="main-manage">
      {isAdmin !== null ? (
        <>
          {isAdmin ? (
            <>
              <button
                className="fw-bold border-0 bg-transparent"
                onClick={() => {
                  navigate("/");
                }}>
                <ImageSvgs name="goBack" /> Back
              </button>

              <div>
                <>
                  <p className="main-title">Admin Screens</p>

                  {getRes && userData ? (
                    <>
                      <div className="card-container">
                        <Container fluid>
                          <Row>
                            <Col sm={2} className="default-user-col">
                              <AdminCards
                                className="default-card"
                                cardType="Users"
                                icon={<ImageSvgs name="users" />}
                                data={cardData && cardData.users}
                              />
                            </Col>
                            <Col sm={2}>
                              <AdminCards
                                cardType="Applications"
                                icon={<ImageSvgs name="application" />}
                                data={cardData && cardData.application}
                                link="/manage_applications?page=table"
                              />
                            </Col>
                            <Col sm={2}>
                              <AdminCards
                                cardType="Trainings"
                                icon={<ImageSvgs name="training" />}
                                data={cardData && cardData.Training}
                                link={"/manage_trainings?page=table"}
                              />
                            </Col>
                            <Col sm={2}>
                              <AdminCards
                                cardType="Communities"
                                icon={<ImageSvgs name="communities" />}
                                data={cardData && cardData.community}
                                link={"/manage_communities?page=communities"}
                              />
                            </Col>
                            <Col sm={2}>
                              <AdminCards
                                cardType="Tools"
                                icon={<ImageSvgs name="tools" />}
                                data={cardData && cardData.tool}
                                link={"/manage_tools?page=tool"}
                              />
                            </Col>
                            <Col sm={2}>
                              <AdminCards
                                cardType="Processes"
                                icon={<ImageSvgs name="process" />}
                                data={cardData && cardData.Process}
                                link={"/manage_process?page=process"}
                              />
                            </Col>
                            <Col sm={2} className="mt-2">
                              <AdminCards
                                cardType="Finance Fields"
                                icon={<ImageSvgs name="financeFieldsAdmin" />}
                                data={cardData && cardData.finance_field}
                                link={"/manage_fields"}
                              />
                            </Col>
                            <Col sm={2} className="mt-2">
                              <AdminCards
                                cardType="Learnings"
                                icon={<ImageSvgs name="learningsAdmin" />}
                                data={cardData && cardData.learning}
                                link={"/manage_learnings"}
                              />
                            </Col>
                          </Row>
                        </Container>
                      </div>

                      <>
                        <div className="sub-bar d-flex justify-content-between">
                          <p className="main-title">Manage Users</p>
                          <div className="d-flex" style={{ width: "30rem" }}>
                            <div
                              className="app-primary-input-field me-3"
                              style={{ width: "19rem" }}>
                              <input
                                type="search"
                                placeholder=" Search Users..."
                                onChange={handleUserSearch}
                              />
                            </div>

                            <Button
                              className="app-secondary-btn"
                              onClick={() => {
                                setAddModalShow(true);
                              }}>
                              Add Admin User
                            </Button>
                          </div>
                        </div>

                        <div className="user-table">
                          {
                            <>
                              <UserTable
                                getRes={showLoader}
                                data={userData}
                                included={included && included}
                              />
                              <div className="d-flex justify-content-end">
                                <ResponsivePagination
                                  current={currentPage}
                                  total={totalPage}
                                  onPageChange={handlePageChange}
                                />
                              </div>
                            </>
                          }
                        </div>
                      </>
                    </>
                  ) : (
                    <div style={{ maxWidth: "100%", height: "35rem" }}>
                      <Loader />
                    </div>
                  )}

                  <AddUserModal
                    show={addModalShow}
                    onHide={() => setAddModalShow(false)}
                    added="null"
                    isLoad={isLoad}
                    setIsLoad={setIsLoad}
                  />
                </>
              </div>
            </>
          ) : (
            <div className="non-admin">
              <ImageSvgs name="non_admin" />
              <h1 className="text-danger">Unauthorized user!</h1>
              <h4>You do not have admin access rights.</h4>
              <button
                id="common-button"
                onClick={() => {
                  navigate("/");
                }}>
                Go to Home
              </button>
            </div>
          )}
        </>
      ) : (
        <div style={{ maxWidth: "100%", height: "100vh" }}>
          <Loader />
        </div>
      )}
    </div>
  );
};
export default MainScreen;
