import { ReactComponent as Redirect } from "../../assets/images/redirect.svg";
import { ReactComponent as Mail } from "../../assets/images/mail.svg";
import { ReactComponent as Copy } from "../../assets/images/copy.svg";
import { ReactComponent as ExpertMail } from "../../assets/images/expertMail.svg";
import { ReactComponent as ExpertCopy } from "../../assets/images/expertCopy.svg";
import { ReactComponent as Warning } from "../../assets/images/warning.svg";
import { ReactComponent as RemoveAdmin } from "../../assets/images/remove_admin.svg";
import { ReactComponent as AddAdmin } from "../../assets/images/add_admin.svg";
import { ReactComponent as NonAdmin } from "../../assets/images/non_admin.svg";
import { ReactComponent as UiLayer } from "../../assets/images/uiLayer.svg";
import { ReactComponent as IntLayer } from "../../assets/images/intLayer.svg";
import { ReactComponent as SourceLayer } from "../../assets/images/sourceLayer.svg";
import { ReactComponent as TickMark } from "../../assets/images/tickMark.svg";
import { ReactComponent as NoUseLayer } from "../../assets/images/noUseLayer.svg";
import { ReactComponent as RelationIcon } from "../../assets/images/relationIcon.svg";
import { ReactComponent as Back } from "../../assets/images/back.svg";
import { ReactComponent as User } from "../../assets/images/user.svg";
import { ReactComponent as Application } from "../../assets/images/application.svg";
import { ReactComponent as Training } from "../../assets/images/trainings.svg";
import { ReactComponent as Tools } from "../../assets/images/tools.svg";
import { ReactComponent as Communities } from "../../assets/images/communities.svg";
import { ReactComponent as AddTool } from "../../assets/images/addTool.svg";
import { ReactComponent as AppListIcon } from "../../assets/images/appListIcon.svg";
import { ReactComponent as Progress1 } from "../../assets/images/progress_1.svg";
import { ReactComponent as Progress2 } from "../../assets/images/progress_2.svg";
import { ReactComponent as Progress3 } from "../../assets/images/progress_3.svg";
import { ReactComponent as TrainingDefault } from "../../assets/images/training_default.svg";
import { ReactComponent as EmployeeAddBtn } from "../../assets/images/modal_btn.svg";
import { ReactComponent as Process } from "../../assets/images/process.svg";
import { ReactComponent as SearchBar } from "../../assets/images/search.svg";
import { ReactComponent as AdminCheck } from "../../assets/images/isAdminCheck.svg";
import { ReactComponent as NotAdminCheck } from "../../assets/images/notAdminCheck.svg";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/deleteIcon.svg";
import { ReactComponent as UILayer } from "../../assets/images/ui_layer.svg";
import { ReactComponent as SouLayer } from "../../assets/images/source_layer.svg";
import { ReactComponent as IntegrationLayer } from "../../assets/images/integration.svg";
import { ReactComponent as NotInUse } from "../../assets/images/not_in_use.svg";
import { ReactComponent as UploadImageIcon } from "../../assets/images/upload_image.svg";
import { ReactComponent as FilterData } from "../../assets/images/filter.svg";
import { ReactComponent as DefaultIamge } from "../../assets/images/default_img_community_user.svg";
import { ReactComponent as TeamsImage } from "../../assets/images/teams.svg";
import { ReactComponent as AppIcon } from "../../assets/images/app_icon.svg";
import { ReactComponent as InitialLoad } from "../../assets/images/level1.svg";
import { ReactComponent as Confirmation } from "../../assets/images/level2.svg";
import { ReactComponent as EditInitialLoad } from "../../assets/images/editProcess1.svg";
import { ReactComponent as EditConfirmation } from "../../assets/images/editProcess2.svg";
import { ReactComponent as SupportIcon } from "../../assets/images/supportIcon.svg";
import { ReactComponent as NoPreviewImage } from "../../assets/images/no_preview_img.svg";
import { ReactComponent as PrivateComImage } from "../../assets/images/privateIcon.svg";
import { ReactComponent as WidgetDelete } from "../../assets/images/widget_delete.svg";
import { ReactComponent as EditWidget } from "../../assets/images/edit_widgets.svg";
import { ReactComponent as FilterLogo } from "../../assets/images/filterLogo.svg";
import { ReactComponent as AppLogo } from "../../assets/images/application_icon.svg";
import { ReactComponent as ProcessLogo } from "../../assets/images/process_icon.svg";
import { ReactComponent as ToolLogo } from "../../assets/images/tools_icon.svg";
import { ReactComponent as MissLogo } from "../../assets/images/miscellaneous_icon.svg";
import { ReactComponent as NoData } from "../../assets/images/noData.svg";
import { ReactComponent as TeamsIcon } from "../../assets/images/teamsIcon.svg";
import { ReactComponent as OtherLink } from "../../assets/images/otherLink_icong.svg";
import { ReactComponent as VivaEngage } from "../../assets/images/viva_engageIcon.svg";
import { ReactComponent as SharePointLink } from "../../assets/images/sharepointIcon.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/settingsIcon.svg";
import { ReactComponent as ThumbsUp } from "../../assets/images/thumbsUp.svg";
import { ReactComponent as ThumbsDown } from "../../assets/images/thumbsDown.svg";
import { ReactComponent as ActiveThumbsUp } from "../../assets/images/activeThumbsUp.svg";
import { ReactComponent as ActiveThumbsDown } from "../../assets/images/activeThumbsDown.svg";
import { ReactComponent as AppCopy } from "../../assets/images/appCopy.svg";
import { ReactComponent as NoTrainings } from "../../assets/images/noTrainings.svg";
import { ReactComponent as AddIcon } from "../../assets/images/add_btn.svg";
import { ReactComponent as MaxIcon } from "../../assets/images/max_view.svg";
import { ReactComponent as DefaultDashboard } from "../../assets/images/default_daashboard.svg";
import { ReactComponent as RedirectBtn } from "../../assets/images/redirect_btn.svg";
import { ReactComponent as ActiveRedirectBtn } from "../../assets/images/active_redirect_btn.svg";
import { ReactComponent as CardViewBtn } from "../../assets/images/cardView.svg";
import { ReactComponent as MapViewBtn } from "../../assets/images/mapView.svg";
import { ReactComponent as CardViewDisableBtn } from "../../assets/images/cardViewDisabled.svg";
import { ReactComponent as MapViewDisableBtn } from "../../assets/images/mapViewDisabled.svg";
import { ReactComponent as TableIcon } from "../../assets/images/table_icon.svg";
import { ReactComponent as Rating } from "../../assets/images/rating_star.svg";
import { ReactComponent as LearningsAdminIcon } from "../../assets/images/learningsAdmin.svg";
import { ReactComponent as FinanceFieldAdminIcon } from "../../assets/images/financeFieldAdmin.svg";
import { ReactComponent as DisableTableIcon } from "../../assets/images/disabled_table.svg";

const ImageSvgs = ({ name }) => {
  return (
    <>
      {name === "redirect" && <Redirect />}
      {name === "mail" && <Mail />}
      {name === "copy" && <Copy />}
      {name === "expertMail" && <ExpertMail />}
      {name === "expertCopy" && <ExpertCopy />}
      {name === "warning" && <Warning />}
      {name === "remove_admin" && <RemoveAdmin />}
      {name === "add_admin" && <AddAdmin />}
      {name === "non_admin" && <NonAdmin />}
      {name === "uiLayer" && <UiLayer />}
      {name === "intLayer" && <IntLayer />}
      {name === "sourceLayer" && <SourceLayer />}
      {name === "tickMark" && <TickMark />}
      {name === "noUseLayer" && <NoUseLayer />}
      {name === "relationIcon" && <RelationIcon />}
      {name === "goBack" && <Back />}
      {name === "users" && <User />}
      {name === "application" && <Application />}
      {name === "training" && <Training />}
      {name === "tools" && <Tools />}
      {name === "communities" && <Communities />}
      {name === "process" && <Process />}
      {name === "search" && <SearchBar />}
      {name === "isAdminCheck" && <AdminCheck />}
      {name === "notAdminCheck" && <NotAdminCheck />}
      {name === "edit" && <EditIcon />}
      {name === "delete" && <DeleteIcon />}
      {name === "ui_layer" && <UILayer />}
      {name === "integration_layer" && <IntegrationLayer />}
      {name === "source_layer" && <SouLayer />}
      {name === "not_in_use" && <NotInUse />}
      {name === "upload_image" && <UploadImageIcon />}
      {name === "filter_data" && <FilterData />}
      {name === "addTool" && <AddTool />}
      {name === "appListIcon" && <AppListIcon />}
      {name === "app_icon" && <AppIcon />}
      {name === "progress_1" && <Progress1 />}
      {name === "progress_2" && <Progress2 />}
      {name === "progress_3" && <Progress3 />}
      {name === "default_img" && <DefaultIamge />}
      {name === "teams_img" && <TeamsImage />}
      {name === "initial_process" && <InitialLoad />}
      {name === "confirmation" && <Confirmation />}
      {name === "edit_process" && <EditInitialLoad />}
      {name === "edit_confirmation" && <EditConfirmation />}
      {name === "training_default" && <TrainingDefault />}
      {name === "employee_add" && <EmployeeAddBtn />}
      {name === "supportIcon" && <SupportIcon />}
      {name === "no_preview_img" && <NoPreviewImage />}
      {name === "privateIcon" && <PrivateComImage />}
      {name === "filterLogo" && <FilterLogo />}
      {name === "widgets_delete" && <WidgetDelete />}
      {name === "edit_widgets" && <EditWidget />}
      {name === "app_indicator" && <AppLogo />}
      {name === "process_indicator" && <ProcessLogo />}
      {name === "tool_indicator" && <ToolLogo />}
      {name === "miss_indicator" && <MissLogo />}
      {name === "no_data" && <NoData />}
      {name === "teams_icon" && <TeamsIcon />}
      {name === "sharepoint_img" && <SharePointLink />}
      {name === "others_img" && <OtherLink />}
      {name === "vivaengage_img" && <VivaEngage />}
      {name === "settings_icon" && <SettingsIcon />}
      {name === "thumbs_up" && <ThumbsUp />}
      {name === "thumbs_down" && <ThumbsDown />}
      {name === "active_thumbs_up" && <ActiveThumbsUp />}
      {name === "active_thumbs_down" && <ActiveThumbsDown />}
      {name === "app_copy" && <AppCopy />}
      {name === "no_trainings" && <NoTrainings />}
      {name === "max_icon" && <MaxIcon />}
      {name === "add_icon" && <AddIcon />}
      {name === "default_dashboard" && <DefaultDashboard />}
      {name === "redirect_btn" && <RedirectBtn />}
      {name === "active_redirect_btn" && <ActiveRedirectBtn />}
      {name === "cardView" && <CardViewBtn />}
      {name === "mapView" && <MapViewBtn />}
      {name === "cardViewDisable" && <CardViewDisableBtn />}
      {name === "mapViewDisable" && <MapViewDisableBtn />}
      {name === "tableView" && <TableIcon />}
      {name === "disableTableView" && <DisableTableIcon />}
      {name === "rating" && <Rating />}
      {name === "learningsAdmin" && <LearningsAdminIcon />}
      {name === "financeFieldsAdmin" && <FinanceFieldAdminIcon />}
    </>
  );
};

export default ImageSvgs;
